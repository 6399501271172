import FileNoFallback from "@trunkery/internal/lib/vature-gen/fragments/FileNoFallback";
import ProductReview from "@trunkery/internal/lib/vature-gen/fragments/ProductReview";
import ProductReviewAnswer from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewAnswer";
import ProductReviewScore from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewScore";
import ReviewDimension from "@trunkery/internal/lib/vature-gen/fragments/ReviewDimension";

import type { GetCustomerOwnProductReviewQueryMeta } from "../types";

export default (FileNoFallback +
  ProductReview +
  ProductReviewAnswer +
  ProductReviewScore +
  ReviewDimension +
  "query GetCustomerOwnProductReview($shopID:String!$productID:String!$customerID:String!){ownReviewsPage:storefrontProductReviewsPage(shop_id:$shopID product_id:$productID customer_id:$customerID limit:1){count items{...ProductReview}}}") as unknown as GetCustomerOwnProductReviewQueryMeta;
