import React from "react";
import { DateTime } from "luxon";
import { ProductQuestionAnswerFragment } from "@trunkery/internal/lib/vature-gen/types";
import { ReportWidget } from "components/ReportWidget";
import { TextBody } from "components/TextBody";
import { VoteWidget } from "components/VoteWidget";

import { T } from "./QuestionItemAnswer.tlocale";

interface QuestionItemAnswerProps {
  item: ProductQuestionAnswerFragment | null;
  onVoteAnswer: (answerID: string, y: boolean, del: boolean) => void;
  onFlagAnswer: (answerID: string, del: boolean) => void;
}

export class QuestionItemAnswer extends React.Component<QuestionItemAnswerProps> {
  render() {
    const { item, onVoteAnswer, onFlagAnswer } = this.props;
    if (!item) return null;
    const authorName =
      (item.user ? item.user.display_name : undefined) ||
      (item.customer ? item.customer.display_name : undefined) ||
      T("Vature Customer");
    return (
      <div className="product-question-answer">
        <div className="product-question-answer__header">
          <div className="product-question-answer__author">{authorName}</div>
          <div className="product-question-answer__date">
            {DateTime.fromISO(item.created_at).toLocaleString(DateTime.DATE_MED)}
          </div>
        </div>
        <TextBody
          text={item.text}
          bodyClass="product-question-answer__body"
          showFullClass="product-question-answer__show-full"
          linkText={T("Show Full Answer")}
          animateLink
        />
        <div className="product-question-answer__footer">
          <div className="product-question-answer__vote">
            <VoteWidget
              yes_count={item.yes_count}
              no_count={item.no_count}
              voted={item.voted}
              onVoteYes={() => {
                onVoteAnswer(item.id, true, false);
              }}
              onVoteNo={() => {
                onVoteAnswer(item.id, false, false);
              }}
              onVoteDelete={() => {
                onVoteAnswer(item.id, false, true);
              }}
            />
          </div>
          <div className="product-question-answer__report">
            <ReportWidget
              flagged={item.flagged}
              onFlag={() => {
                onFlagAnswer(item.id, false);
              }}
              onUnflag={() => {
                onFlagAnswer(item.id, true);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
