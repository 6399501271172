import FileNoFallback from "@trunkery/internal/lib/vature-gen/fragments/FileNoFallback";
import ProductReview from "@trunkery/internal/lib/vature-gen/fragments/ProductReview";
import ProductReviewAnswer from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewAnswer";
import ProductReviewScore from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewScore";
import ReviewDimension from "@trunkery/internal/lib/vature-gen/fragments/ReviewDimension";

import type { ModifyProductReviewMutationMeta } from "../types";

export default (FileNoFallback +
  ProductReview +
  ProductReviewAnswer +
  ProductReviewScore +
  ReviewDimension +
  "mutation ModifyProductReview($shopID:String!$id:String!$data:StorefrontProductReviewModify!){reviews:storefrontProductReviewsModify(shop_id:$shopID ids:[$id]data:[$data]){...ProductReview}}") as unknown as ModifyProductReviewMutationMeta;
