import classnames from "classnames";
import React, { useState } from "react";
import { MultilineString } from "components/MultilineString";

interface TextBodyProps {
  text: string;
  bodyClass: string;
  showFullClass: string;
  linkText: string;
  animateLink?: boolean;
}

export const TextBody = (props: TextBodyProps) => {
  const { text, bodyClass, showFullClass, linkText, animateLink } = props;
  const [expanded, setExpanded] = useState(false);
  const [elementHeight, setElementHeight] = useState(-1);
  const needsExpansion = elementHeight > 63;
  const linkVisible = !expanded && needsExpansion;
  return (
    <>
      <div className={bodyClass}>
        <div className="animated-max-height" style={{ lineHeight: "21px", maxHeight: expanded ? elementHeight : 63 }}>
          <div
            ref={(e) => {
              if (e) {
                setElementHeight(e.clientHeight);
              }
            }}
          >
            <MultilineString value={text} />
          </div>
        </div>
      </div>
      {linkVisible || animateLink ? (
        <div
          className={classnames(showFullClass, { "animated-height": animateLink })}
          style={{ height: (expanded || !needsExpansion) && animateLink ? 0 : undefined }}
        >
          <span
            className="review-blue-link"
            onClick={() => {
              setExpanded(true);
            }}
          >
            {linkText}
          </span>
        </div>
      ) : null}
    </>
  );
};
