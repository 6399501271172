import React from "react";

import { T } from "./ReportWidget.tlocale";

interface ReportWidgetProps {
  flagged: boolean;
  onFlag: () => void;
  onUnflag: () => void;
}

export class ReportWidget extends React.Component<ReportWidgetProps> {
  handleClick = () => {
    const { flagged, onFlag, onUnflag } = this.props;
    if (flagged) {
      onUnflag();
    } else {
      onFlag();
    }
  };

  render() {
    const { flagged } = this.props;
    if (flagged) {
      return (
        <span className="review-reported" onClick={this.handleClick}>
          {T("Thanks for reporting it!")}
        </span>
      );
    }
    return (
      <span className="review-light-link" onClick={this.handleClick}>
        {T("Report as inappropriate")}
      </span>
    );
  }
}
