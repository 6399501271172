import React from "react";
import compact from "lodash/compact";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { DateTime } from "luxon";
import { EditOrCreateAnswer } from "components/EditOrCreateAnswer";
import { MultilineString } from "components/MultilineString";
import { ProductQuestionFragment } from "@trunkery/internal/lib/vature-gen/types";
import { QuestionItemAnswer } from "components/QuestionItemAnswer";
import { QuestionsModel } from "utils/questionsModel";
import { ReportWidget } from "components/ReportWidget";
import { SlideToggleWrapper } from "components/SlideToggleWrapper";
import { VoteWidget } from "components/VoteWidget";
import { observable } from "mobx";
import { observer } from "mobx-react";

import { T } from "./QuestionItem.tlocale";

interface AnswerLike {
  yes_count: number;
  no_count: number;
}

function sortAnswersByRating<T extends AnswerLike>(answers: (T | null)[] | null) {
  const ans = compact(answers);
  return sortBy(ans, (a) => -(a.yes_count - a.no_count));
}

interface QuestionItemProps {
  item: ProductQuestionFragment;
  model: QuestionsModel;
}

@observer
export class QuestionItem extends React.Component<QuestionItemProps> {
  @observable showAllAnswers = false;

  handleShowAllAnswersClick = () => {
    this.showAllAnswers = true;
  };

  handleShowAnswer = () => {
    const { item, model } = this.props;
    model.onShowAnswerForID(item.id);
  };

  handleFlagAnswer = (answerID: string, del: boolean) => {
    const { item, model } = this.props;
    model.onFlagAnswer(item.id, answerID, del);
  };

  handleVoteAnswer = (answerID: string, y: boolean, del: boolean) => {
    const { item, model } = this.props;
    model.onVoteAnswer(item.id, answerID, y, del);
  };

  render() {
    const { item, model } = this.props;
    const customerName = (item.customer ? item.customer.display_name : undefined) || T("Vature Customer");
    const answers = sortAnswersByRating(item.answers);
    return (
      <div className="product-question product-questions__question">
        <div className="product-question__header">
          <div className="product-question__header-question">
            <div className="product-question__title">
              <MultilineString value={item.text} />
            </div>
            <div className="product-question__author">{customerName}</div>
            <div className="product-question__answer-button">
              <button type="button" className="review-button" onClick={this.handleShowAnswer}>
                {T("Answer")}
              </button>
            </div>
          </div>
          <div className="product-question__header-vote-and-date">
            <div className="product-question__date">
              {DateTime.fromISO(item.created_at).toLocaleString(DateTime.DATE_MED)}
            </div>
            <div className="product-question__vote">
              <VoteWidget
                yes_count={item.yes_count}
                no_count={item.no_count}
                voted={item.voted}
                onVoteYes={() => {
                  model.onVote(item.id, true, false);
                }}
                onVoteNo={() => {
                  model.onVote(item.id, false, false);
                }}
                onVoteDelete={() => {
                  model.onVote(item.id, false, true);
                }}
              />
            </div>
          </div>
        </div>
        <SlideToggleWrapper
          expanded={model.questionAnswerFormVisibleOnID === item.id}
          render={({ setCollapsibleElement }) => (
            <EditOrCreateAnswer model={model} setCollapsibleElement={setCollapsibleElement} />
          )}
        />
        {answers.length > 0 ? (
          <div className="product-question__answers">
            <QuestionItemAnswer
              item={answers[0]}
              onFlagAnswer={this.handleFlagAnswer}
              onVoteAnswer={this.handleVoteAnswer}
            />
            {this.showAllAnswers
              ? map(answers, (a, idx) =>
                  idx !== 0 ? (
                    <QuestionItemAnswer
                      key={idx}
                      item={a}
                      onFlagAnswer={this.handleFlagAnswer}
                      onVoteAnswer={this.handleVoteAnswer}
                    />
                  ) : null
                )
              : null}
          </div>
        ) : (
          <div className="product-question__be-first">{T("Be the first to answer this question!")}</div>
        )}
        {answers.length > 1 && !this.showAllAnswers ? (
          <div className="product-question__show-more">
            {answers.length === 2 ? (
              <span className="review-blue-link" onClick={this.handleShowAllAnswersClick}>
                {T("Show 1 more answer")}
              </span>
            ) : (
              <span className="review-blue-link" onClick={this.handleShowAllAnswersClick}>
                {T("Show {count} more answers", { count: answers.length - 1 })}
              </span>
            )}
          </div>
        ) : null}
        <div className="product-question__report">
          <ReportWidget
            flagged={item.flagged}
            onFlag={() => {
              model.onFlag(item.id, false);
            }}
            onUnflag={() => {
              model.onFlag(item.id, true);
            }}
          />
        </div>
      </div>
    );
  }
}
