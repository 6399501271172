import Category from "@trunkery/internal/lib/vature-gen/fragments/Category";
import CurrencyPrice from "@trunkery/internal/lib/vature-gen/fragments/CurrencyPrice";
import CustomField from "@trunkery/internal/lib/vature-gen/fragments/CustomField";
import CustomFieldObjects from "@trunkery/internal/lib/vature-gen/fragments/CustomFieldObjects";
import File from "@trunkery/internal/lib/vature-gen/fragments/File";
import KeyValue from "@trunkery/internal/lib/vature-gen/fragments/KeyValue";
import MediaFile from "@trunkery/internal/lib/vature-gen/fragments/MediaFile";
import MinCurrencyPrice from "@trunkery/internal/lib/vature-gen/fragments/MinCurrencyPrice";
import Product from "@trunkery/internal/lib/vature-gen/fragments/Product";
import ProductFlat from "@trunkery/internal/lib/vature-gen/fragments/ProductFlat";
import ProductReviewAverageScore from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewAverageScore";
import ReviewDimension from "@trunkery/internal/lib/vature-gen/fragments/ReviewDimension";
import ReviewDimensionSet from "@trunkery/internal/lib/vature-gen/fragments/ReviewDimensionSet";
import TieredPricing from "@trunkery/internal/lib/vature-gen/fragments/TieredPricing";
import TieredPricingTier from "@trunkery/internal/lib/vature-gen/fragments/TieredPricingTier";
import Variant from "@trunkery/internal/lib/vature-gen/fragments/Variant";
import VariantOption from "@trunkery/internal/lib/vature-gen/fragments/VariantOption";

import type { GetProductQueryMeta } from "../types";

export default (Category +
  CurrencyPrice +
  CustomField +
  CustomFieldObjects +
  File +
  KeyValue +
  MediaFile +
  MinCurrencyPrice +
  Product +
  ProductFlat +
  ProductReviewAverageScore +
  ReviewDimension +
  ReviewDimensionSet +
  TieredPricing +
  TieredPricingTier +
  Variant +
  VariantOption +
  "query GetProduct($shopID:String!$productID:String!){storefrontProducts(shop_id:$shopID ids:[$productID]){...Product}}") as unknown as GetProductQueryMeta;
