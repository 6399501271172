export type SortingMode = "most_recent" | "highest_score" | "lowest_score" | "highest_rating" | "lowest_rating";

interface SortingModeVars {
  sortBy: "rating" | "average_score" | "created_at";
  sortDesc: boolean;
}

interface SortingModeVarsForQuestions {
  sortBy: "rating" | "created_at";
  sortDesc: boolean;
}

export function sortingModeToVars(m: SortingMode): SortingModeVars {
  switch (m) {
    case "highest_rating":
      return { sortBy: "rating", sortDesc: true };
    case "highest_score":
      return { sortBy: "average_score", sortDesc: true };
    case "lowest_rating":
      return { sortBy: "rating", sortDesc: false };
    case "lowest_score":
      return { sortBy: "average_score", sortDesc: false };
    case "most_recent":
      return { sortBy: "created_at", sortDesc: true };
  }
}

export function sortingModeToVarsForQuestions(m: SortingMode): SortingModeVarsForQuestions {
  const v = sortingModeToVars(m);
  return {
    ...v,
    sortBy: questionsSortingMode(v.sortBy),
  };
}

export function questionsSortingMode(v: SortingModeVars["sortBy"]): "rating" | "created_at" {
  return v === "average_score" ? "rating" : v;
}
