import React from "react";
import classnames from "classnames";

export const imgHalf = require("images/star-half.svg").default;
export const imgFull = require("images/star-filled-2.svg").default;
export const imgEmpty = require("images/star-6.svg").default;

export interface ReadonlyRatingProps {
  value: number;
  big?: boolean;
  allowHalf?: boolean;
}

// 0 - [/ x x x x]    >= 0 && < 1
// 1 - [* x x x x]    >= 1
// 2 - [* / x x x]    >= 2 && < 3
// 3 - [* * x x x]    >= 3
// 4 - [* * / x x]    >= 4 && < 5
// 5 - [* * * x x]    >= 5
// 6 - [* * * / x]    >= 6 && < 7
// 7 - [* * * * x]    >= 7
// 8 - [* * * * /]    >= 8 && < 9
// 9 - [* * * * *]    >= 9
export const renderWithHalfStars = (v: number) => (
  <>
    <img src={v < 0 ? imgEmpty : v < 1 ? imgHalf : imgFull} />
    <img src={v < 2 ? imgEmpty : v < 3 ? imgHalf : imgFull} />
    <img src={v < 4 ? imgEmpty : v < 5 ? imgHalf : imgFull} />
    <img src={v < 6 ? imgEmpty : v < 7 ? imgHalf : imgFull} />
    <img src={v < 8 ? imgEmpty : v < 9 ? imgHalf : imgFull} />
  </>
);

// 0 - [* x x x x]
// 1 - [* x x x x]
// 2 - [* x x x x]
// 3 - [* * x x x]
// 4 - [* * x x x]
// 5 - [* * * x x]
// 6 - [* * * x x]
// 7 - [* * * * x]
// 8 - [* * * * x]
// 9 - [* * * * *]
export const renderWithFullStars = (v: number) => (
  <>
    <img src={v < 0 ? imgEmpty : imgFull} />
    <img src={v < 3 ? imgEmpty : imgFull} />
    <img src={v < 5 ? imgEmpty : imgFull} />
    <img src={v < 7 ? imgEmpty : imgFull} />
    <img src={v < 9 ? imgEmpty : imgFull} />
  </>
);

export const renderStars = (value: number, allowHalf: boolean) =>
  allowHalf ? renderWithHalfStars(value) : renderWithFullStars(value);

export class ReadonlyRating extends React.Component<ReadonlyRatingProps> {
  render() {
    const { value, big, allowHalf } = this.props;
    return (
      <div className={classnames("rating-stars", "rating-stars--read-only", { "rating-stars--big": big })}>
        {renderStars(value, !!allowHalf)}
      </div>
    );
  }
}
