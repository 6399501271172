import React from "react";
import { Environment } from "@trunkery/internal/lib/environment";
import { FormGroup } from "components/FormGroup";
import { FormInnerProps, formalizeExternal } from "@trunkery/internal/lib/formaline/react";
import { ImageListUploader } from "components/ImageListUploader";
import { ProductRatingWidget } from "components/ProductRatingWidget";
import { RecommendedWidget } from "components/RecommendedWidget";
import { SignInLink } from "components/SignInLink";
import { reviewFormDefinition } from "utils/reviewsModel";

import { T } from "./ReviewForm.tlocale";

interface ReviewFormProps {
  pending: boolean;
  submitted: boolean;
  error: boolean;
  editing: boolean;
  loggedIn: boolean;
  env: Environment;
  onCancel: () => void;
  setCollapsibleElement: (e: any) => void;
}

export const ReviewForm = formalizeExternal<typeof reviewFormDefinition, ReviewFormProps>(
  class extends React.Component<FormInnerProps<typeof reviewFormDefinition, ReviewFormProps>> {
    render() {
      const {
        form: { scores, title, text, image_files, recommended },
        pending,
        submitted,
        error,
        handleSubmit,
        onCancel,
        loggedIn,
        setCollapsibleElement,
        env,
      } = this.props;
      const showMessage = submitted || error || !loggedIn;
      const messageText = submitted ? (
        <div>
          <div>{T("Thank you for writing a product review.")}</div>
          <div>{T("Your review will be published in the next few days.")}</div>
        </div>
      ) : error ? (
        <div>{T("Error submitting review.")}</div>
      ) : !loggedIn ? (
        <div>
          <SignInLink className="default-blue-link">{T("Sign in to write a review.")}</SignInLink>
        </div>
      ) : null;
      const hiddenStyle = showMessage ? { visibility: "hidden" as "hidden" } : undefined;
      return (
        <div className="product-question-form-wrapper" ref={setCollapsibleElement}>
          <div className="product-question-form">
            <div className="product-question-form__title" style={hiddenStyle}>
              {T("Write a Review")}
            </div>
            <form onSubmit={handleSubmit} style={hiddenStyle}>
              <FormGroup className="product-question-form__field" field={text}>
                <div className="product-question-form__label">{T("Title")}</div>
                <input type="text" className="review-form-input" {...title.text} style={{ maxWidth: 550 }} />
              </FormGroup>
              <div className="product-question-form__field">
                <ProductRatingWidget scores={scores} />
              </div>
              <FormGroup className="product-question-form__field" field={text}>
                <div className="product-question-form__label">{T("Review")}</div>
                <textarea className="review-form-textarea" {...text.text} rows={3} style={{ maxWidth: 550 }} />
              </FormGroup>
              <div className="product-question-form__field">
                <RecommendedWidget recommended={recommended} />
              </div>
              <div className="product-question-form__field">
                <div className="product-question-form__label">{T("Images")}</div>
                <ImageListUploader env={env} image_files={image_files} />
                <div className="product-question-form__note">{T("Up to 5 images less than 10MB each accepted")}</div>
              </div>
              <div className="product-question-form__buttons">
                <button
                  className="review-button review-button--reversed product-question-form__button"
                  disabled={pending}
                  type="button"
                  onClick={onCancel}
                >
                  {T("Cancel")}
                </button>
                <button
                  className="review-button product-question-form__button"
                  disabled={pending || !this.props.form.isValid}
                >
                  {T("Save")}
                </button>
              </div>
            </form>
            {showMessage ? <div className="product-question-form__message">{messageText}</div> : null}
          </div>
          <div style={{ height: 25 }} />
        </div>
      );
    }
  }
);
