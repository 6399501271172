import classnames from "classnames";
import map from "lodash/map";
import some from "lodash/some";
import React, { useEffect, useRef, useState } from "react";
import { Config } from "@trunkery/internal/lib/vatureTypes";
import { LazyImage } from "components/LazyImage";
import { MediaFileFragment } from "@trunkery/internal/lib/vature-gen/types";
import { ModelViewerAsync } from "components/ModelViewerAsync";
import { VideoViewerAsync } from "components/VideoViewerAsync";
import { convertImageURL } from "@lana-commerce/core/convertImageURL";
import { imageFallback } from "utils/imageFallback";
import { supportedImageMIMEs } from "@lana-commerce/core/media";
import { useSiteData } from "utils/useSiteData";

import Siema from "../vendor/siema/siema.js";

type Siema = any;

function contain(aspectRatio: number, containerWidth: number, containerHeight: number) {
  let w = containerWidth;
  let h = w * aspectRatio;
  if (h > containerHeight) {
    h = containerHeight;
    w = h / aspectRatio;
  }
  return {
    left: (containerWidth - w) / 2,
    top: (containerHeight - h) / 2,
    width: w,
    height: h,
  };
}

// returns max aspect ratio (height / width) of all media files which have their dimensions
// defined
function maxImageAspectRatio(media: MediaFileFragment[]) {
  let maxAspect = 0;
  for (const mf of media) {
    const f = mf.file;
    if (f && (supportedImageMIMEs[f.mime] || f.mime === "video/mp4")) {
      if (f.image_width !== 0) {
        const aspect = f.image_height / f.image_width;
        maxAspect = Math.max(maxAspect, aspect);
      }
    }
  }
  return maxAspect;
}

function renderMediaFileThumbnail(
  mf: MediaFileFragment,
  idx: number,
  current: number,
  siemaRef: React.MutableRefObject<Siema | undefined>,
  config: Config
) {
  const f = mf.file;
  const tf = mf.thumbnail_file;
  if (!f) return null;
  if (supportedImageMIMEs[f.mime]) {
    return (
      <div
        key={f.id}
        className={classnames("product-image__menu-item", {
          "product-image__menu-item--active": current === idx,
        })}
        onClick={() => {
          if (siemaRef.current) siemaRef.current.goTo(idx);
        }}
      >
        <img
          src={convertImageURL(config.cdn, f.public_url, { size: "35x35", crop: "center" })}
          alt={f.image_alt}
          title={f.image_alt}
          width={35}
          height={35}
        />
      </div>
    );
  } else if (tf && supportedImageMIMEs[tf.mime]) {
    return (
      <div
        key={tf.id}
        className={classnames("product-image__menu-item", {
          "product-image__menu-item--active": current === idx,
        })}
        onClick={() => {
          if (siemaRef.current) siemaRef.current.goTo(idx);
        }}
      >
        <img
          src={convertImageURL(config.cdn, tf.public_url, { size: "35x35", crop: "center" })}
          alt={tf.image_alt}
          title={tf.image_alt}
          width={35}
          height={35}
        />
      </div>
    );
  }
  return null;
}

function renderMediaFile(
  mf: MediaFileFragment,
  config: Config,
  maxAspect: number,
  containerWidth: number,
  containerHeight: number
) {
  const f = mf.file;
  // const tf = mf.thumbnail_file;
  if (!f) return null;
  if (supportedImageMIMEs[f.mime]) {
    const style = contain(f.image_height / f.image_width, containerWidth, containerHeight);
    return (
      <div key={f.id}>
        <div className="product-image__frame" style={style}>
          <LazyImage
            src={convertImageURL(config.cdn, f.public_url, { size: "450x600" })}
            fallback={imageFallback(f)}
            alt={f.image_alt}
            title={f.image_alt}
            // {...imageSize(f, { size: "450x600" })}
          />
        </div>
      </div>
    );
  } else if (f.mime === "model/gltf-binary") {
    const style = contain(maxAspect, containerWidth, containerHeight);
    return (
      <div key={f.id}>
        <div className="product-image__frame" style={style}>
          <ModelViewerAsync src={f.public_url} />
        </div>
      </div>
    );
  } else if (f.mime === "video/mp4") {
    const style = contain(f.image_height / f.image_width, containerWidth, containerHeight);
    return (
      <div key={f.id}>
        <div className="product-image__frame" style={style}>
          <VideoViewerAsync mediaWidth={f.image_width} mediaHeight={f.image_height} src={f.public_url} />
        </div>
      </div>
    );
  }
  return null;
}

interface ProductMediaCarouselProps {
  media: MediaFileFragment[];
}

export const ProductMediaCarousel = (props: ProductMediaCarouselProps) => {
  const { media } = props;
  const { config } = useSiteData();
  const [current, setCurrent] = useState(0);
  const [windowWidth, setWindowWidth] = useState(typeof document !== "undefined" ? window.innerWidth : 1280);
  const carouselRef = useRef<HTMLDivElement>(null);
  const siemaRef = useRef<Siema>();
  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  useEffect(() => {
    const cref = carouselRef.current;
    if (!cref) {
      console.warn("no carousel element");
      return;
    }
    const siema = new Siema({
      selector: cref,
      onChange: () => {
        setCurrent(siema.currentSlide);
      },
      draggable: !some(media, (mf) => !supportedImageMIMEs[mf.file?.mime || ""]),
    });

    siemaRef.current = siema;
    return () => {
      siema.destroy();
    };
  }, []);
  const maxAspect = Math.min(600 / 450, maxImageAspectRatio(media));
  const containerWidth = windowWidth >= 1210 ? 450 : windowWidth - 40;
  const containerHeight = maxAspect * containerWidth;
  return (
    <>
      <div className="product-image__content" ref={carouselRef}>
        {map(media, (mf) => renderMediaFile(mf, config, maxAspect, containerWidth, containerHeight))}
        {media.length === 0 ? (
          <div>
            <img src={convertImageURL(config.cdn, "", { size: "450x600" })} width={450} height={600} />
          </div>
        ) : null}
      </div>
      <div className="product-image__menu">
        {map(media, (mf, idx) => renderMediaFileThumbnail(mf, idx, current, siemaRef, config))}
      </div>
    </>
  );
};
