import compact from "lodash/compact";
import createProductReview from "gql/operations/CreateProductReviewMutation";
import deleteProductReview from "gql/operations/DeleteProductReviewMutation";
import findIndex from "lodash/findIndex";
import flagReview from "gql/operations/FlagReviewMutation";
import flagReviewAnswer from "gql/operations/FlagReviewAnswerMutation";
import getReview from "gql/operations/GetReviewQuery";
import map from "lodash/map";
import modifyProductReview from "gql/operations/ModifyProductReviewMutation";
import omit from "lodash/omit";
import searchReviews from "gql/operations/SearchReviewsQuery";
import voteReview from "gql/operations/VoteReviewMutation";
import voteReviewAnswer from "gql/operations/VoteReviewAnswerMutation";
import { ActionLock } from "@lana-commerce/core/actionLock";
import { Auth, authJWT } from "@trunkery/internal/lib/auth";
import { CreateProductReviewMutation, ModifyProductReviewMutation } from "gql/types";
import { Environment } from "@trunkery/internal/lib/environment";
import {
  FArray,
  FBoolean,
  FHidden,
  FNumber,
  FObject,
  FString,
  FormData,
  valid,
} from "@trunkery/internal/lib/formaline";
import { PAGE_SIZE } from "utils/pageSize";
import { ProductReviewFragment } from "@trunkery/internal/lib/vature-gen/types";
import { RequestResponse, prettyPrintRequestResponseError, request } from "@lana-commerce/core/request";
import { SortingMode, sortingModeToVars } from "utils/soringMode";
import { action, computed, observable, runInAction } from "mobx";
import { authCallWrapper } from "utils/authCallWrapper";
import { globalAuthState } from "utils/globalAuthState";
import { globalSnackbarState } from "utils/globalSnackbarState";
import { pender } from "@lana-commerce/core/pender";

import { T } from "./reviewsModel.tlocale";

export const reviewFormDefinition = FObject({
  scores: FArray(
    FObject({
      review_dimension_id: FString(),
      score: FNumber(),
      name: FHidden(FString()),
      overall: FHidden(FBoolean()),
    })
  ),
  title: FString(valid.nonEmpty, valid.maxLength250),
  text: FString(valid.nonEmpty, valid.maxLengthLongText),
  image_files: FArray(
    FObject({
      id: FString(valid.nonEmpty),
      preview: FHidden(FString()),
      public_url: FHidden(FString()),
      uuid: FHidden(FString()),
    })
  ),
  recommended: FString(),
});

interface ReviewsModelArgs {
  productID: string;
  env: Environment;
  reviews: ProductReviewFragment[];
  reviewsTotal: number;
  customerID: string;
  loaded: boolean;
}

interface ReviewsModelLoadedArgs {
  ownReviews: ProductReviewFragment[];
  reviews: ProductReviewFragment[];
  reviewsTotal: number;
  customerID: string;
}

interface PageData {
  items: ProductReviewFragment[];
  count: number | undefined;
}

function insertPageData<T>(n: number, currentData: T[], newData: T[]) {
  let data = [...currentData];
  data = data.slice(0, n * PAGE_SIZE);
  data.push(...newData);
  return data;
}

// zero-based, e.g. 0 is first page
async function loadNthPage(
  n: number,
  env: Environment,
  productID: string,
  customerID: string,
  sortingMode: SortingMode
): Promise<PageData | undefined> {
  if (customerID !== "") {
    const resp = await request(searchReviews)(
      {
        shopID: env.shopID,
        productID,
        notCustomerID: customerID,
        offset: n * PAGE_SIZE,
        limit: PAGE_SIZE,
        ...sortingModeToVars(sortingMode),
      },
      { url: `${env.host}/storefront.json`, authToken: globalAuthState.jwt() }
    );
    if (resp.kind === "data") {
      return {
        ...resp.data,
        items: compact(resp.data.items),
      };
    }
  } else {
    const resp = await fetch(`/product-review-data/${productID}/${sortingMode}/${n + 1}.json`);
    if (resp.status === 200) {
      return {
        items: await resp.json(),
        count: undefined,
      };
    }
  }
}

export class ReviewsModel {
  private productID: string;
  private env: Environment;
  alock = new ActionLock(); // had to make it public for authCallWrapper
  //========================================================================================================
  // STATE
  //========================================================================================================

  // global pending state
  @observable pending = false;

  // review form state
  reviewFormData: FormData<typeof reviewFormDefinition>;
  @observable reviewFormVisible = false;
  @observable reviewFormSubmitted = false;
  @observable reviewFormError = false;
  @observable reviewFormEditingReview: ProductReviewFragment | undefined;

  @observable.ref ownReviews: ProductReviewFragment[] = [];
  @observable.ref reviews: ProductReviewFragment[];
  @observable reviewsTotal: number;
  @observable sortMode: SortingMode = "highest_rating";

  @observable customerID: string; // when logged in, this is our customer id

  //========================================================================================================

  @computed get canLoadMore() {
    return this.reviews.length < this.reviewsTotal;
  }

  //========================================================================================================
  // ACTIONS
  //========================================================================================================

  onLoaded = action((args: ReviewsModelLoadedArgs) => {
    this.ownReviews = args.ownReviews;
    this.reviews = args.reviews;
    this.reviewsTotal = args.reviewsTotal;
    this.customerID = args.customerID;
    this.alock.locked = false;
  });

  onWriteReviewClick = action(() => {
    this.reviewFormVisible = true;
    this.reviewFormSubmitted = false;
    this.reviewFormError = false;
    this.reviewFormEditingReview = undefined;
  });

  onCancelReviewClick = action(() => {
    this.reviewFormVisible = false;
  });

  onChangeSortMode = this.alock.proxy(async (mode: SortingMode) => {
    this.sortMode = mode;
    loadNthPage(0, this.env, this.productID, this.customerID, mode).then((resp) => {
      if (resp) {
        runInAction(() => {
          this.reviews = resp.items;
          if (resp.count) this.reviewsTotal = resp.count;
          console.log(`got ${this.reviews.length} reviews now, total: ${this.reviewsTotal}`);
        });
      }
    });
  });

  onLoadMore = this.alock.proxy(async () => {
    const nextPage = Math.floor(this.reviews.length / PAGE_SIZE);
    loadNthPage(nextPage, this.env, this.productID, this.customerID, this.sortMode).then((resp) => {
      if (resp) {
        runInAction(() => {
          this.reviews = insertPageData(nextPage, this.reviews, resp.items);
          if (resp.count) this.reviewsTotal = resp.count;
          console.log(`got ${this.reviews.length} reviews now, total: ${this.reviewsTotal}`);
        });
      }
    });
  });

  @action replaceReview(r: ProductReviewFragment) {
    const idx = findIndex(this.reviews, (or) => or.id === r.id);
    if (idx !== -1) {
      // if review is in other reviews, replace it
      const newReviews = [...this.reviews];
      newReviews[idx] = r;
      this.reviews = newReviews;
    } else if (this.ownReviews.length > 0 && this.ownReviews[0].id === r.id) {
      // maybe it's our own review? if so, replace it
      this.ownReviews = [r];
    }
  }

  private async fetchAndReplaceReview(reviewID: string) {
    const resp = await request(getReview)(
      { shopID: this.env.shopID, reviewID },
      { url: `${this.env.host}/storefront.json`, authToken: globalAuthState.jwt() }
    );
    if (resp.kind === "data") {
      this.replaceReview(resp.data[0]);
    } else {
      console.error(prettyPrintRequestResponseError(resp));
    }
  }

  private doVote = async (overrideAuth: Auth | undefined, reviewID: string, y: boolean, del: boolean) => {
    const resp = await request(voteReview)(
      {
        shopID: this.env.shopID,
        reviewID,
        y,
        delete: del,
      },
      {
        url: `${this.env.host}/storefront.json`,
        authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
      }
    );
    if (resp.kind !== "data") {
      console.error(prettyPrintRequestResponseError(resp));
    } else {
      const msg = del ? T("Review vote removed") : y ? T("Review upvoted") : T("Review downvoted");
      globalSnackbarState.showMessage(msg);
      if (overrideAuth) {
        globalAuthState.applyAuth(overrideAuth);
      } else {
        await this.fetchAndReplaceReview(reviewID);
      }
    }
  };

  private doVoteAnswer = async (
    overrideAuth: Auth | undefined,
    reviewID: string,
    reviewAnswerID: string,
    y: boolean,
    del: boolean
  ) => {
    const resp = await request(voteReviewAnswer)(
      {
        shopID: this.env.shopID,
        reviewAnswerID,
        y,
        delete: del,
      },
      {
        url: `${this.env.host}/storefront.json`,
        authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
      }
    );
    if (resp.kind !== "data") {
      console.error(prettyPrintRequestResponseError(resp));
    } else {
      const msg = del ? T("Review answer vote removed") : y ? T("Review answer upvoted") : T("Review answer downvoted");
      globalSnackbarState.showMessage(msg);
      if (overrideAuth) {
        globalAuthState.applyAuth(overrideAuth);
      } else {
        await this.fetchAndReplaceReview(reviewID);
      }
    }
  };

  private doFlag = async (overrideAuth: Auth | undefined, reviewID: string, del: boolean) => {
    const resp = await request(flagReview)(
      {
        shopID: this.env.shopID,
        reviewID,
        delete: del,
      },
      {
        url: `${this.env.host}/storefront.json`,
        authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
      }
    );
    if (resp.kind !== "data") {
      console.error(prettyPrintRequestResponseError(resp));
    } else {
      const msg = del ? T("Review report canceled") : T("Review reported");
      globalSnackbarState.showMessage(msg);
      if (overrideAuth) {
        globalAuthState.applyAuth(overrideAuth);
      } else {
        await this.fetchAndReplaceReview(reviewID);
      }
    }
  };

  private doFlagAnswer = async (
    overrideAuth: Auth | undefined,
    reviewID: string,
    reviewAnswerID: string,
    del: boolean
  ) => {
    const resp = await request(flagReviewAnswer)(
      {
        shopID: this.env.shopID,
        reviewAnswerID,
        delete: del,
      },
      {
        url: `${this.env.host}/storefront.json`,
        authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
      }
    );
    if (resp.kind !== "data") {
      console.error(prettyPrintRequestResponseError(resp));
    } else {
      const msg = del ? T("Review answer report canceled") : T("Review answer reported");
      globalSnackbarState.showMessage(msg);
      if (overrideAuth) {
        globalAuthState.applyAuth(overrideAuth);
      } else {
        await this.fetchAndReplaceReview(reviewID);
      }
    }
  };

  onVote = authCallWrapper(this, this.doVote);
  onVoteAnswer = authCallWrapper(this, this.doVoteAnswer);
  onFlag = authCallWrapper(this, this.doFlag);
  onFlagAnswer = authCallWrapper(this, this.doFlagAnswer);

  onDelete = this.alock.proxy(async (id: string) => {
    if (!this.customerID) return; // TODO
    const resp = await request(deleteProductReview)(
      { shopID: this.env.shopID, id },
      { url: `${this.env.host}/storefront.json`, authToken: globalAuthState.jwt() }
    );
    if (resp.kind === "data") {
      globalSnackbarState.showMessage(T("Review deleted"));
      this.ownReviews = [];
    } else {
      console.error(prettyPrintRequestResponseError(resp));
    }
  });

  handleSubmitReview = this.alock.proxy(
    pender(this, async (data: typeof reviewFormDefinition.data) => {
      if (!this.customerID) return; // TODO
      const ownReview = this.ownReviews.length > 0 ? this.ownReviews[0] : undefined;
      let resp: RequestResponse<CreateProductReviewMutation | ModifyProductReviewMutation>;
      if (ownReview) {
        resp = await request(modifyProductReview)(
          {
            shopID: this.env.shopID,
            id: ownReview.id,
            data: {
              ...omit(data, "image_files"),
              image_file_ids: map(data.image_files, (f) => f.id),
              recommended: data.recommended as any,
            },
          },
          { url: `${this.env.host}/storefront.json`, authToken: globalAuthState.jwt() }
        );
      } else {
        resp = await request(createProductReview)(
          {
            shopID: this.env.shopID,
            data: {
              ...omit(data, "image_files"),
              product_id: this.productID,
              image_file_ids: map(data.image_files, (f) => f.id),
              recommended: data.recommended as any,
            },
          },
          { url: `${this.env.host}/storefront.json`, authToken: globalAuthState.jwt() }
        );
      }
      if (resp.kind === "data") {
        globalSnackbarState.showMessage(ownReview ? T("Review modified") : T("Review submitted"));
        const r = resp.data[0];
        this.env.analytics("track", "Product Reviewed", {
          product_id: this.productID,
          review_id: r.id,
          review_body: r.text,
          rating: r.scores[0].score,
        });
        runInAction(() => {
          this.reviewFormSubmitted = true;
          this.ownReviews = [r];
        });
      } else {
        console.error(prettyPrintRequestResponseError(resp));
        this.reviewFormError = true;
      }
    })
  );

  //========================================================================================================
  //========================================================================================================
  constructor(args: ReviewsModelArgs) {
    this.productID = args.productID;
    this.env = args.env;
    this.reviews = args.reviews;
    this.reviewsTotal = args.reviewsTotal;
    this.reviewFormData = new FormData("ProductReview", reviewFormDefinition, this.handleSubmitReview);
    this.customerID = args.customerID;
    this.alock.locked = !args.loaded;
  }
}
