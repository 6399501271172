import React from "react";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import map from "lodash/map";
import { ArrayWrapper, HiddenWrapper, ObjectWrapperType, StringValueWrapper } from "@trunkery/internal/lib/formaline";
import { Environment } from "@trunkery/internal/lib/environment";
import { FileNoFallbackFragment } from "@trunkery/internal/lib/vature-gen/types";
import { ResultErrorCode, resultErrorCodeToString, uploadFileInChunks } from "utils/file";
import { Spinner } from "components/Spinner";
import { acceptImagePattern } from "utils/acceptImagePattern";
import { action, observable } from "mobx";
import { convertImageURL } from "@lana-commerce/core/convertImageURL";
import { generateUUID } from "@lana-commerce/core/generateUUID";
import { observer } from "mobx-react";

import { T } from "./ImageListUploader.tlocale";

class UploadingImageState {
  @observable uploading = true;
  @observable error = false;
  @observable errorCode: ResultErrorCode | undefined = undefined;
  constructor(public uuid: string) {}

  @action setError(errorCode: ResultErrorCode) {
    this.error = true;
    this.errorCode = errorCode;
  }
}

interface UploadingImageProps {
  img: ImageFormType;
  state?: UploadingImageState;
  onDelete: (id: string, uuid: string) => void;
  env: Environment;
}

@observer
class UploadingImage extends React.Component<UploadingImageProps> {
  handleDelete = () => {
    const { img, state, onDelete } = this.props;
    onDelete(img.id.value, state ? state.uuid : "");
  };

  renderPreview() {
    const { img, env } = this.props;
    const publicURL = img.public_url.value.value;
    if (publicURL) {
      const url = convertImageURL(env.cdn, publicURL, { crop: "center", size: "85x80" });
      return <img src={url} className="image-list-uploader__image-preview" />;
    }
    const preview = img.preview.value.value;
    return preview ? <img src={preview} className="image-list-uploader__image-preview" /> : null;
  }

  render() {
    const { state } = this.props;
    const error = state && state.error ? state.errorCode || "is_uploaded_failure" : undefined;
    const isLoading = !!(state && !error && state.uploading);
    return (
      <div className="image-list-uploader__image">
        {this.renderPreview()}
        {isLoading ? (
          <div className="image-list-uploader__loading">
            <Spinner white />
          </div>
        ) : null}
        {error ? (
          <div className="image-list-uploader__error" title={resultErrorCodeToString(error)}>
            {T("Error")}
          </div>
        ) : null}
        <div className="image-list-uploader__delete" onClick={this.handleDelete}>
          <img src={require("images/delete-photo.svg").default} />
        </div>
      </div>
    );
  }
}

//==============================================================================================================
//==============================================================================================================
//==============================================================================================================

type ImageFormType = ObjectWrapperType<{
  id: StringValueWrapper;
  preview: HiddenWrapper<StringValueWrapper>;
  public_url: HiddenWrapper<StringValueWrapper>;
  uuid: HiddenWrapper<StringValueWrapper>;
}>;

interface FileUploadedArgs {
  uuid: string;
  image_files: ArrayWrapper<ImageFormType>;
  state: UploadingImageState;
  file: FileNoFallbackFragment;
}

const fileUploaded = action((p: FileUploadedArgs) => {
  const f = find(p.image_files.value, (f) => f.uuid.value.value === p.uuid);
  if (f) {
    f.id.set(p.file.id);
  }
  p.state.uploading = false;
});

const fileErrored = action((state: UploadingImageState, code: ResultErrorCode) => {
  state.setError(code);
});

function isValidFile(f: File) {
  if (f.size > 10 * 1024 * 1024) {
    return false;
  }
  return true;
}

interface ImageListUploaderProps {
  image_files: ArrayWrapper<ImageFormType>;
  env: Environment;
}

@observer
export class ImageListUploader extends React.Component<ImageListUploaderProps> {
  uploadingImageMap = observable.map<string, UploadingImageState>();
  fileRef: HTMLInputElement | null = null;

  handleFileDrop = action((ev: React.ChangeEvent<HTMLInputElement>) => {
    const { image_files, env } = this.props;
    const { files } = ev.target;
    if (!files) return;
    for (let i = 0; i < files.length; i++) {
      if (image_files.value.length >= 5) continue;
      const f = files[i];
      const uuid = generateUUID();
      const preview = URL.createObjectURL(f);
      image_files.addItem({
        preview,
        uuid,
      });
      const state = new UploadingImageState(uuid);
      this.uploadingImageMap.set(uuid, state);
      if (!isValidFile(f)) {
        fileErrored(state, "is_uploaded_failure");
      } else {
        (async () => {
          const result = await uploadFileInChunks(env, f);
          if (result.kind === "ok") {
            fileUploaded({ uuid, image_files, state, file: result.data });
          } else {
            fileErrored(state, result.kind);
          }
        })();
      }
    }
  });

  handleClickAdd = () => {
    if (this.fileRef) this.fileRef.click();
  };

  handleDeleteImage = action((id: string, uuid: string) => {
    const { image_files } = this.props;
    const idx =
      (id ? findIndex(image_files.value, (f) => f.id.value === id) : undefined) ||
      findIndex(image_files.value, (f) => f.uuid.value.value === uuid);
    if (idx !== -1) {
      image_files.removeItem(idx);
      if (uuid) {
        this.uploadingImageMap.delete(uuid);
      }
    }
  });

  render() {
    const { image_files, env } = this.props;
    return (
      <div className="image-list-uploader">
        {map(image_files.value, (img) => (
          <UploadingImage
            env={env}
            key={img.id.value || img.uuid.value.value}
            img={img}
            state={this.uploadingImageMap.get(img.uuid.value.value)}
            onDelete={this.handleDeleteImage}
          />
        ))}
        {image_files.value.length < 5 ? (
          <div
            className="image-list-uploader__image image-list-uploader__image--clickable"
            onClick={this.handleClickAdd}
          >
            <img src={require("images/add-photo.svg").default} />
          </div>
        ) : null}
        <input
          value=""
          multiple
          ref={(e) => {
            this.fileRef = e;
          }}
          onChange={this.handleFileDrop}
          style={{ display: "none" }}
          type="file"
          accept={acceptImagePattern}
        />
      </div>
    );
  }
}
