import compact from "lodash/compact";
import find from "lodash/find";
import map from "lodash/map";
import { ReviewDimensionFragment } from "@trunkery/internal/lib/vature-gen/types";

interface ReviewDimensionSet {
  overall_review_dimension: {
    id: string;
  } | null;
  review_dimensions: Array<{
    id: string;
  } | null> | null;
}

interface Score {
  review_dimension: {
    id: string;
  } | null;
  score: number;
}

function findScoreInPrevScores(rdID: string, prevScores: Score[] | undefined) {
  const s = find(prevScores, (s) => (s.review_dimension ? s.review_dimension.id === rdID : rdID === ""));
  return s ? s.score : 0;
}

function defaultInitialScoresValue(prevScores: Score[] | undefined) {
  return [{ review_dimension_id: "", score: findScoreInPrevScores("", prevScores) || 9, name: "" }];
}

export function rdsToInitialScoresValue(
  rds: ReviewDimensionSet | null | undefined,
  reviewDimensions: ReviewDimensionFragment[],
  prevScores?: Score[]
) {
  if (!rds) {
    return defaultInitialScoresValue(prevScores);
  }

  const overallID = rds.overall_review_dimension ? rds.overall_review_dimension.id : "";
  const arr = compact(
    map(rds.review_dimensions, (rd) => {
      if (!rd) return undefined;
      const v = find(reviewDimensions, (v) => v.id === rd.id);
      if (!v) return undefined;
      return {
        review_dimension_id: v.id,
        name: v.name,
        score: findScoreInPrevScores(v.id, prevScores) || 9,
        overall: v.id === overallID,
      };
    })
  );
  return arr.length > 0 ? arr : defaultInitialScoresValue(prevScores);
}
