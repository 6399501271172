import FileNoFallback from "@trunkery/internal/lib/vature-gen/fragments/FileNoFallback";
import ProductReview from "@trunkery/internal/lib/vature-gen/fragments/ProductReview";
import ProductReviewAnswer from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewAnswer";
import ProductReviewScore from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewScore";
import ReviewDimension from "@trunkery/internal/lib/vature-gen/fragments/ReviewDimension";

import type { SearchReviewsQueryMeta } from "../types";

export default (FileNoFallback +
  ProductReview +
  ProductReviewAnswer +
  ProductReviewScore +
  ReviewDimension +
  "query SearchReviews($shopID:String!$productID:String!$offset:Int!$limit:Int!$sortBy:SearchReviewsSortBy!$sortDesc:Boolean!$notCustomerID:String){storefrontSearchReviews(shop_id:$shopID product_id:$productID offset:$offset limit:$limit sort_by:$sortBy sort_desc:$sortDesc not_customer_id:$notCustomerID){count items{...ProductReview}}}") as unknown as SearchReviewsQueryMeta;
