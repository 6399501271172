import React from "react";
import map from "lodash/map";
import { FlippingImages } from "components/FlippingImages";
import { ProductFragment } from "@trunkery/internal/lib/vature-gen/types";
import { SaveBadge } from "components/SaveBadge";
import { VariantAnchor } from "components/VariantAnchor";
import { VariantPrice } from "components/VariantPrice";
import { defaultVariant } from "@lana-commerce/core/defaultVariant";
import { getCustomFieldProduct } from "utils/getCustomFieldProduct";
import { getCustomFields } from "utils/getCustomFields";
import { useSiteData } from "utils/useSiteData";
import { variantImages } from "@lana-commerce/core/variantImages";

import { T } from "./YouMightAlsoLike.tlocale";

interface YouMightAlsoLikeProps {
  product: ProductFragment;
}

export const YouMightAlsoLike = (props: YouMightAlsoLikeProps) => {
  const relatedProducts = getCustomFields<ContentModels.vature_related_product>(
    props.product,
    "vature_related_product"
  );
  if (relatedProducts.length === 0) return null;
  const { shop, currencies } = useSiteData();
  return (
    <>
      <div className="page-with-menu__header">{T("You might also like")}</div>
      <div className="product-items product-items--large">
        {map(relatedProducts, (id, idx) => {
          const product = getCustomFieldProduct(id, props.product.custom_field_objects);
          if (!product) return null;
          const variant = defaultVariant(product);
          if (!variant) return null;
          const images = variantImages(variant);
          return (
            <div className="product-items__item" key={idx}>
              <div className="product-items__image">
                <VariantAnchor variant={variant}>
                  <div className="img-co img-co--3to2 img-co--img-contain">
                    <FlippingImages images={images} width={525} height={350} />
                    <SaveBadge variant={variant} shop={shop} currencies={currencies} />
                  </div>
                </VariantAnchor>
              </div>
              <div className="product-items__title">
                <VariantAnchor className="default-blue-link" variant={variant}>
                  {product.title}
                </VariantAnchor>
              </div>
              <div className="product-items__price">
                <VariantPrice variant={variant} shop={shop} currencies={currencies} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
