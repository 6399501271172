import React from "react";
import classnames from "classnames";
import { ReadonlyRatingProps, renderStars } from "components/ReadonlyRating";
import { action, observable } from "mobx";
import { observer } from "mobx-react";

interface RatingProps extends ReadonlyRatingProps {
  onChange?: (v: number) => void;
}

@observer
export class Rating extends React.Component<RatingProps> {
  @observable hover = false;
  @observable hoverScore = 0;
  elementRef: HTMLDivElement | null = null;

  @action applyHoverScore(e: React.MouseEvent<any>) {
    if (!this.elementRef || !this.props.onChange) return;

    const r = this.elementRef.getBoundingClientRect();
    const one = r.width / 5;
    const x = Math.max(0, e.pageX - r.left);
    // 1, 3, 5, 7, 9
    this.hover = true;
    this.hoverScore = Math.min(9, 1 + Math.floor(x / one) * 2);
  }

  @action cancelHover() {
    this.hover = false;
    this.hoverScore = 0;
  }

  handleMouseEnter = (e: React.MouseEvent<any>) => {
    this.applyHoverScore(e);
  };
  handleMouseMove = (e: React.MouseEvent<any>) => {
    this.applyHoverScore(e);
  };
  handleMouseLeave = () => {
    this.cancelHover();
  };

  handleClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    if (this.props.onChange) this.props.onChange(this.hoverScore);
  };

  render() {
    const { value, big, allowHalf } = this.props;
    const readOnly = !this.props.onChange;
    return (
      <div
        ref={(e) => {
          this.elementRef = e;
        }}
        className={classnames("rating-stars", { "rating-stars--read-only": readOnly, "rating-stars--big": big })}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.handleMouseMove}
        onClick={this.handleClick}
      >
        {renderStars(this.hover ? this.hoverScore : value, !!allowHalf)}
      </div>
    );
  }
}
