import React from "react";
import { FormGroup } from "components/FormGroup";
import { FormInnerProps, formalizeExternal } from "@trunkery/internal/lib/formaline/react";
import { Link } from "gatsby";
import { Spinner } from "components/Spinner";
import { paths } from "utils/paths";
import { signInFormDefinition } from "components/SignInForm";

import { T } from "./SignInDialogForm.tlocale";

interface SignInDialogFormProps {
  pending: boolean;
  error: boolean;
}

export const SignInDialogForm = formalizeExternal<typeof signInFormDefinition, SignInDialogFormProps>(
  class extends React.Component<FormInnerProps<typeof signInFormDefinition, SignInDialogFormProps>> {
    render() {
      const {
        form: { email, password },
        error,
        pending,
        handleSubmit,
      } = this.props;
      return (
        <form onSubmit={handleSubmit}>
          <div className="dialog-form">
            {error ? <div className="alert margin-block">{T("Invalid email or password!")}</div> : null}
            <FormGroup field={email}>
              <div className="form-label">{T("Email")}</div>
              <input type="email" className="form-input" {...email.text} />
            </FormGroup>
            <FormGroup field={password}>
              <div className="form-label">{T("Password")}</div>
              <input type="password" className="form-input" {...password.text} />
            </FormGroup>
            <div className="margin-block">
              <button
                disabled={pending || !this.props.form.isValid}
                className="banner-button banner-button--full-width"
              >
                {pending ? <Spinner /> : T("Sign In")}
              </button>
            </div>
            <div className="tacenter default-font margin-block">
              {T("Don’t have an account?")}{" "}
              <Link className="default-link" to={paths.accountSignup}>
                {T("Sign Up")}
              </Link>
            </div>
            <div className="tacenter default-font">
              <Link className="default-link" to={paths.accountPasswordReset}>
                {T("Forgot password?")}
              </Link>
            </div>
          </div>
        </form>
      );
    }
  }
);
