interface ImageFile {
  image_color: string;
  image_width: number;
  image_height: number;
}

export function colorFallback(f: ImageFile) {
  return {
    color: `#${f.image_color}`,
    width: f.image_width,
    height: f.image_height,
  };
}
