import React from "react";
import { StorefrontCustomerVote } from "@trunkery/internal/lib/vature-gen/types";

interface VoteWidgetProps {
  yes_count: number;
  no_count: number;
  voted: StorefrontCustomerVote;
  onVoteYes: () => void;
  onVoteNo: () => void;
  onVoteDelete: () => void;
}

export class VoteWidget extends React.Component<VoteWidgetProps> {
  handleYesClicked = () => {
    const { voted, onVoteDelete: onCancelVote, onVoteYes } = this.props;
    if (voted === "voted_yes") {
      onCancelVote();
    } else {
      onVoteYes();
    }
  };

  handleNoClicked = () => {
    const { voted, onVoteDelete: onCancelVote, onVoteNo } = this.props;
    if (voted === "voted_no") {
      onCancelVote();
    } else {
      onVoteNo();
    }
  };

  render() {
    const { yes_count, no_count, voted } = this.props;
    return (
      <div className="vote">
        <div className="vote__thumb-up" onClick={this.handleYesClicked}>
          {voted === "voted_yes" ? (
            <img width={20} height={17} src={require("images/thumb-up-active.svg").default} />
          ) : (
            <img width={20} height={17} src={require("images/thumb-up.svg").default} />
          )}
          <span>{yes_count}</span>
        </div>
        <div className="vote__thumb-down" onClick={this.handleNoClicked}>
          {voted === "voted_no" ? (
            <img width={20} height={17} src={require("images/thumb-down-active.svg").default} />
          ) : (
            <img width={20} height={17} src={require("images/thumb-down.svg").default} />
          )}
          <span>{no_count}</span>
        </div>
      </div>
    );
  }
}
