import React from "react";
import map from "lodash/map";
import split from "lodash/split";

interface MultilineStringProps {
  value: string;
}

export class MultilineString extends React.Component<MultilineStringProps> {
  render() {
    const lines = split(this.props.value, "\n");
    return map(lines, (item, idx) => (
      <React.Fragment key={idx}>
        {item}
        {idx !== lines.length - 1 ? <br /> : null}
      </React.Fragment>
    ));
  }
}
