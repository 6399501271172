import React from "react";
import compact from "lodash/compact";
import { FileFragment } from "@trunkery/internal/lib/vature-gen/types";
import { toHTML } from "@lana-commerce/core/slate";
import { useSiteData } from "utils/useSiteData";

interface RawContentProps {
  rawContent: string;
  contentImageFiles: (FileFragment | null)[] | null;
  nth?: number;
  className?: string;
}

export const RawContent = (props: RawContentProps) => {
  const imageFiles = props.contentImageFiles ? compact(props.contentImageFiles) : [];
  const { config } = useSiteData();
  let html = "";
  try {
    if (props.rawContent) {
      html = toHTML(config.cdn, JSON.parse(props.rawContent), imageFiles)[props.nth || 0];
    }
  } catch (err) {
    console.warn(err);
  }
  return <div className={props.className} dangerouslySetInnerHTML={{ __html: html }} />;
};
