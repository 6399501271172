import React from "react";
import { VideoViewerProps } from "components/VideoViewer";
import { useAsyncCachedValue } from "utils/useAsyncCachedValue";
import { videoViewerModule } from "modules/videoViewerModule";

export const VideoViewerAsync = (props: VideoViewerProps) => {
  const m = useAsyncCachedValue(videoViewerModule);
  const VideoViewer = m?.VideoViewer;
  return VideoViewer ? <VideoViewer {...props} /> : <div />;
};
