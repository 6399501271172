import FileNoFallback from "@trunkery/internal/lib/vature-gen/fragments/FileNoFallback";
import ProductReview from "@trunkery/internal/lib/vature-gen/fragments/ProductReview";
import ProductReviewAnswer from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewAnswer";
import ProductReviewScore from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewScore";
import ReviewDimension from "@trunkery/internal/lib/vature-gen/fragments/ReviewDimension";

import type { CreateProductReviewMutationMeta } from "../types";

export default (FileNoFallback +
  ProductReview +
  ProductReviewAnswer +
  ProductReviewScore +
  ReviewDimension +
  "mutation CreateProductReview($shopID:String!$data:StorefrontProductReviewCreate!){reviews:storefrontProductReviewsCreate(shop_id:$shopID data:[$data]){...ProductReview}}") as unknown as CreateProductReviewMutationMeta;
