import React from "react";
import SlideToggle from "react-slide-toggle";

interface SlideToggleWrapperRenderArgs {
  setCollapsibleElement: (e: any) => void;
}

interface SlideToggleWrapperProps {
  expanded: boolean;
  render: (args: SlideToggleWrapperRenderArgs) => JSX.Element;
}

export class SlideToggleWrapper extends React.Component<SlideToggleWrapperProps> {
  questionFormToggleRef: any = null;
  expanded = false;

  componentWillMount() {
    const { expanded } = this.props;
    this.expanded = expanded;
  }

  componentWillReceiveProps(newProps: Readonly<SlideToggleWrapperProps>) {
    if (newProps.expanded !== this.props.expanded) {
      this.expanded = newProps.expanded;
      if (this.questionFormToggleRef) this.questionFormToggleRef.toggle();
    }
  }

  render() {
    const { render } = this.props;
    return (
      <SlideToggle
        ref={(e: any) => {
          this.questionFormToggleRef = e;
        }}
        collapsed={!this.expanded}
        duration={500}
        bestPerformance
        render={(args: any) => render(args)}
      />
    );
  }
}
