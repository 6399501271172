import Helmet from "react-helmet";
import compact from "lodash/compact";
import getCustomerOwnProductQuestion from "gql/operations/GetCustomerOwnProductQuestionQuery";
import getCustomerOwnProductReview from "gql/operations/GetCustomerOwnProductReviewQuery";
import getProduct from "gql/operations/GetProductQuery";
import getProductReviewImages from "gql/operations/GetProductReviewImagesQuery";
import join from "lodash/join";
import map from "lodash/map";
import maxBy from "lodash/maxBy";
import searchQuestions from "gql/operations/SearchQuestionsQuery";
import searchReviews from "gql/operations/SearchReviewsQuery";
import React, { useCallback, useEffect, useState } from "react";
import { Environment } from "@trunkery/internal/lib/environment";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { ImageViewDialogState } from "components/ImageViewDialog";
import { Layout } from "components/Layout";
import { Link } from "gatsby";
import { PAGE_SIZE } from "utils/pageSize";
import { ProductFragment, ReviewSettingsFragment } from "@trunkery/internal/lib/vature-gen/types";
import { ProductMediaCarousel } from "components/ProductMediaCarousel";
import { ProductReviewsAndQuestions } from "components/ProductReviewsAndQuestions";
import { QueryBuilder } from "utils/queryBuilder";
import { QuestionsModel } from "utils/questionsModel";
import { RawContent } from "components/RawContent";
import { ReadonlyRating } from "components/ReadonlyRating";
import {
  RequestResponseMulti,
  prettyPrintGraphErrors,
  prettyPrintRequestResponseError,
  request,
  requestMulti,
} from "@lana-commerce/core/request";
import { ReviewsModel } from "utils/reviewsModel";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { SaveBadge } from "components/SaveBadge";
import { YouMightAlsoLike } from "components/YouMightAlsoLike";
import { analyticsProductData } from "@trunkery/internal/lib/analyticsProductData";
import { authCustomerID } from "@trunkery/internal/lib/auth";
import { averageReviewScore } from "@lana-commerce/core/averageReviewScore";
import { cartModule } from "modules/cartModule";
import { categoryChain } from "utils/categoryChain";
import { categoryLink } from "utils/categoryLink";
import { convertImageURL } from "@lana-commerce/core/convertImageURL";
import { defaultVariant } from "@lana-commerce/core/defaultVariant";
import { formatCurrencyFromList } from "utils/formatCurrencyFromList";
import { getCustomField, getCustomFields } from "utils/getCustomFields";
import { getCustomFieldFile } from "utils/getCustomFieldFile";
import { globalAuthState } from "utils/globalAuthState";
import { isSoldOut } from "utils/isSoldOut";
import { nonNull } from "@lana-commerce/core/nonNull";
import { productLink } from "utils/productLink";
import { respItems } from "utils/respItems";
import { toMajor } from "@trunkery/internal/lib/toMajor";
import { useAsyncCachedValue } from "utils/useAsyncCachedValue";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";
import { useSiteData } from "utils/useSiteData";
import { variantImages } from "@lana-commerce/core/variantImages";
import { variantPriceInfo } from "utils/variantPriceInfo";

import { T } from "./Product.tlocale";

interface ProductReviewsBriefProps {
  product: ProductFragment;
  reviewSettings: ReviewSettingsFragment;
}

const ProductReviewsBrief = (props: ProductReviewsBriefProps) => {
  const { product, reviewSettings } = props;
  const numReviews = maxBy(product.average_scores, (s) => s.count)?.count || 0;
  const averageScore = averageReviewScore(product, reviewSettings);
  return (
    <>
      {numReviews !== 0 ? (
        <div>
          <ReadonlyRating value={averageScore} allowHalf />
        </div>
      ) : null}
      <div className="margin-block">
        {numReviews === 0 ? (
          <a className="review-link" href="#reviews">
            {T("Write a review")}
          </a>
        ) : (
          <a className="review-link" href="#reviews">
            {T("{number} reviews", { number: numReviews })}
          </a>
        )}
      </div>
    </>
  );
};

interface AllIngredientsProps {
  value: string;
}

const AllIngredients = (props: AllIngredientsProps) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      {!visible ? (
        <div className="product-description__paragraph">
          <a
            onClick={() => {
              setVisible(true);
            }}
            className="product-description__ingredients-link"
            id="the-view-ingredients-link"
          >
            {T("View all ingredients")}
          </a>
        </div>
      ) : null}
      {visible ? <div className="product-description__paragraph">{props.value}</div> : null}
    </>
  );
};

function makeLoadMoreReviewImagesStatic(productID: string) {
  return async function loadMoreReviewImagesStatic(page: number) {
    const resp = await fetch(`/product-review-image-data/${productID}/${page + 1}.json`);
    if (resp.status !== 200) {
      return [];
    } else {
      return await resp.json();
    }
  };
}

function makeLoadMoreReviewImagesDynamic(env: Environment, productID: string) {
  return async function loadMoreReviewImagesDynamic(page: number) {
    const resp = await request(getProductReviewImages)(
      {
        shopID: env.shopID,
        productID,
        offset: page * PAGE_SIZE,
        limit: PAGE_SIZE,
      },
      { url: `${env.host}/storefront.json`, authToken: globalAuthState.jwt() }
    );
    if (resp.kind === "data") {
      return resp.data.items;
    } else {
      return [];
    }
  };
}

function dumpErrorsMaybe<T>(resp: RequestResponseMulti<T>) {
  if (resp.kind === "data_or_error") {
    if (resp.errors) {
      console.error(prettyPrintGraphErrors(resp.errors));
    }
  } else {
    console.error(prettyPrintRequestResponseError(resp));
  }
  return resp;
}

async function loadPageData(env: Environment, productID: string, customerID: string) {
  const commonVars = {
    shopID: env.shopID,
    productID,
  };
  const sortByVars = {
    ...commonVars,
    sortBy: "rating" as const,
    sortDesc: true,
    notCustomerID: customerID,
    offset: 0,
    limit: 10, // we don't use PAGE_SIZE here, because initial query should get only 10 items as static build does
  };
  const qb = new QueryBuilder()
    .addQuery(getProduct)({ ...commonVars })
    .addQuery(searchQuestions)({ ...commonVars, ...sortByVars })
    .addQuery(searchReviews)({ ...commonVars, ...sortByVars })
    .addQuery(getCustomerOwnProductQuestion)({ ...commonVars, customerID })
    .addQuery(getCustomerOwnProductReview)({ ...commonVars, customerID })
    .addQuery(getProductReviewImages)({ ...commonVars, offset: 0, limit: PAGE_SIZE });
  const q = qb.assemble();
  return dumpErrorsMaybe(
    await requestMulti(q.query)(q.vars, { url: `${env.host}/storefront.json`, authToken: globalAuthState.jwt() })
  );
}

interface AddToCartButtonProps {
  variantID: string;
}

const AddToCartButton = (props: AddToCartButtonProps) => {
  const m = useAsyncCachedValue(cartModule);
  const onClick = useCallback(() => {
    if (m) {
      m.incDecItem(props.variantID, 1);
      m.show();
    }
  }, [m]);
  return (
    <button type="button" onClick={onClick} className="add-to-cart-button">
      {T("ADD TO CART")}
    </button>
  );
};

type PageData = RouteData.Product & {
  loaded: boolean;
};

export default ({ pageContext }: { pageContext: RouteData.Product }) => {
  const env = useEnvironment();
  const { shop, categoryBriefs, currencies, config, reviewSettings, reviewDimensions } = useSiteData();
  const [pageData, setPageData] = useState<PageData>({ ...pageContext, loaded: !globalAuthState.isLoggedIn() });
  const {
    category,
    product,
    questions,
    questionsTotal,
    reviewImages,
    reviewImagesTotal,
    reviews,
    reviewsTotal,
    loaded,
  } = pageData;

  const [reviewsModel] = useState(
    () =>
      new ReviewsModel({
        env,
        productID: product.id,
        reviews: reviews || [],
        reviewsTotal: reviewsTotal || 0,
        customerID: authCustomerID(globalAuthState.auth) || "",
        loaded,
      })
  );
  const [questionsModel] = useState(
    () =>
      new QuestionsModel({
        env,
        productID: product.id,
        questions: questions || [],
        questionsTotal: questionsTotal || 0,
        customerID: authCustomerID(globalAuthState.auth) || "",
        loaded,
      })
  );
  const [imageViewDialogState] = useState(() => {
    return new ImageViewDialogState();
  });

  useEffect(() => {
    // on mount we should load more relevant data if customer is logged in
    let canceled = false;
    if (globalAuthState.isLoggedIn()) {
      loadPageData(env, product.id, nonNull(globalAuthState.customerID(), "customer id")).then((resp) => {
        if (canceled) return;
        const newPageData: PageData = {
          product: respItems(resp)?.storefrontProducts?.[0] || product,
          category,
          questions: compact(respItems(resp)?.storefrontSearchQuestions?.items || questions),
          questionsTotal: respItems(resp)?.storefrontSearchQuestions?.count || questionsTotal,
          reviews: compact(respItems(resp)?.storefrontSearchReviews?.items || reviews),
          reviewsTotal: respItems(resp)?.storefrontSearchReviews?.count || reviewsTotal,
          reviewImages: compact(respItems(resp)?.storefrontProductReviewsImagesPage?.items || reviewImages),
          reviewImagesTotal: respItems(resp)?.storefrontProductReviewsImagesPage?.count || reviewImagesTotal,
          loaded: true,
        };
        setPageData(newPageData);
        reviewsModel.onLoaded({
          ownReviews: respItems(resp)?.ownReviewsPage?.items || [],
          reviews: newPageData.reviews || [],
          reviewsTotal: newPageData.reviewsTotal || 0,
          customerID: nonNull(globalAuthState.customerID(), "customer id"),
        });
        questionsModel.onLoaded({
          ownQuestions: respItems(resp)?.ownQuestionsPage?.items || [],
          questions: newPageData.questions || [],
          questionsTotal: newPageData.questionsTotal || 0,
          customerID: nonNull(globalAuthState.customerID(), "customer id"),
        });
      });
    }
    return () => {
      canceled = true;
    };
  }, []);

  const variant = defaultVariant(product);
  if (!variant) {
    console.warn(`no default variant on product ${product.id}`);
    return null;
  }

  const media = variant.media_files;
  const images = variantImages(variant);
  const priceInfo = variantPriceInfo(variant);
  const featuredIngredients = getCustomFields<ContentModels.vature_featured_ingredient>(
    product,
    "vature_featured_ingredient"
  );
  const allIngredients = getCustomField<ContentModels.vature_all_ingredients>(product, "vature_all_ingredients");
  useEffect(() => {
    env.analytics("track", "Product Viewed", analyticsProductData(env, env.shopCurrency, variant));
  }, []);

  const loadMoreImages = globalAuthState.isLoggedIn()
    ? {
        totalCount: reviewImagesTotal || 0,
        perPage: PAGE_SIZE,
        loadMore: makeLoadMoreReviewImagesDynamic(env, product.id),
      }
    : {
        totalCount: reviewImagesTotal || 0,
        perPage: PAGE_SIZE,
        loadMore: makeLoadMoreReviewImagesStatic(product.id),
      };

  return (
    <Layout>
      <Helmet defer={false}>
        <meta name="title" content={product.meta_title} />
        <meta name="description" content={product.meta_description} />
        {category ? (
          <title>
            {join(
              [
                shop.name,
                ...map(categoryChain(category.id, categoryBriefs).slice(0, -1), (c) => c.title),
                product.title,
              ],
              " | "
            )}
          </title>
        ) : (
          <title>{join([shop.name, product.title], " | ")}</title>
        )}

        <link rel="canonical" href={productLink(product, "", {})} />
        {/* === OPEN GRAPH === */}
        <meta property="og:type" content="product" />
        <meta property="og:title" content={product.meta_title} />
        <meta property="og:url" content={productLink(product, "", {})} />
        <meta property="og:price:amount" content={`${toMajor(env, shop.currency, variant.price)}`} />
        <meta property="og:price:currency" content={shop.currency} />
        <meta property="og:availability" content={(variant.inventory?.available || 0) > 0 ? "instock" : "oos"} />
        <meta property="og:description" content={product.meta_description} />
        {/* === TWITTER CARD === */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={product.meta_title} />
        <meta name="twitter:description" content={product.meta_description} />
      </Helmet>
      {/* === OPEN GRAPH images === */}
      {map(images, (f) => (
        <Helmet key={`og-${f.id}`} defer={false}>
          <meta property="og:image" content={f.public_url} />
          <meta property="og:image:secure_url" content={f.public_url} />
        </Helmet>
      ))}
      {/* === TWITTER CARD images === */}
      {map(images, (f) => (
        <Helmet key={`twitter-${f.id}`} defer={false}>
          <meta name="twitter:image" content={f.public_url} />
        </Helmet>
      ))}
      <div className="page-with-menu">
        <div className="page-with-menu__content">
          <div className="breadcrumbs">
            {category ? (
              <>
                {shop.name}
                {map(categoryChain(category.id, categoryBriefs), (c) => (
                  <React.Fragment key={c.id}>
                    {" / "}
                    <Link to={categoryLink(c.id, categoryBriefs)}>{c.title}</Link>
                  </React.Fragment>
                ))}
                {" / "}
                {product.title}
              </>
            ) : (
              <>
                {shop.name}
                {" / "}
                {product.title}
              </>
            )}
          </div>
        </div>
        <div className="page-with-menu__content page-with-menu__content--no-padding">
          <div className="product-header">
            <div className="product-header__image product-header__image--auto">
              <div className="product-image">
                <ProductMediaCarousel media={media} />
                <SaveBadge shop={shop} currencies={currencies} variant={variant} />
              </div>
            </div>
            <div className="product-header__content product-header__content--auto">
              <h1 className="product-header__title">{product.title}</h1>
              <div className="product-header__price">
                {formatCurrencyFromList(shop.currency, currencies, priceInfo.price)}
              </div>
              <div className="product-header__description">
                {(variant.inventory?.available || 0) > 0 && variant.inventory?.low_stock ? (
                  <div className="margin-block">
                    <span className="badge badge--blue">{T("Low Stock")}</span>
                    <br />
                  </div>
                ) : null}
                {shop.product_review_enabled ? (
                  <ProductReviewsBrief product={product} reviewSettings={reviewSettings} />
                ) : null}
                <div className="product-header__buttons">
                  <div className="margin-block">
                    {isSoldOut(variant.inventory) ? (
                      <button className="add-to-cart-button add-to-cart-button--disabled" disabled>
                        {T("SOLD OUT")}
                      </button>
                    ) : (
                      <AddToCartButton variantID={variant.id} />
                    )}
                  </div>
                </div>
                <RawContent
                  className="margin-block"
                  rawContent={product.raw_content}
                  contentImageFiles={product.content_image_files}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-with-menu__content">
          <div className="product-description">
            <RawContent rawContent={product.raw_content} contentImageFiles={product.content_image_files} nth={1} />
            {featuredIngredients.length > 0 ? (
              <>
                <h2 className="product-description__header">{T("Key Ingredients")}</h2>
                <div className="product-description__ingredients">
                  {map(featuredIngredients, (ingr, idx) => (
                    <div key={idx} className="product-description__ingredient">
                      <div className="product-description__ingredient-image">
                        <img
                          src={convertImageURL(
                            config.cdn,
                            getCustomFieldFile(ingr.image, product.custom_field_objects)?.public_url,
                            { size: "150x150", crop: "center" }
                          )}
                        />
                      </div>
                      <div className="product-description__ingredient-title">{ingr.title}</div>
                      <div className="product-description__ingredient-desc">{ingr.description}</div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
            {allIngredients ? <AllIngredients value={allIngredients} /> : null}
          </div>
        </div>
        <ProductReviewsAndQuestions
          shop={shop}
          product={product}
          reviewSettings={reviewSettings}
          reviewImages={reviewImages || []}
          reviewImagesLoadMore={loadMoreImages}
          reviewDimensions={reviewDimensions}
          reviewsModel={reviewsModel}
          questionsModel={questionsModel}
          imageViewDialogState={imageViewDialogState}
        />
        <div className="page-with-menu__content">
          <YouMightAlsoLike product={product} />
        </div>
        <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
          <FooterMenu />
          <Footer />
        </div>
      </div>
    </Layout>
  );
};
