import createFile from "gql/operations/CreateFileMutation";
import fileUploaded from "gql/operations/FileUploadedMutation";
import getFileStatus from "gql/operations/GetFileStatusQuery";
import uploadFile from "gql/operations/UploadFileMutation";
import { Environment } from "@trunkery/internal/lib/environment";
import { FileNoImageFallbackFragment } from "gql/types";
import { authJWT } from "@trunkery/internal/lib/auth";
import { globalAuthState } from "utils/globalAuthState";
import { prettyPrintRequestResponseError, request } from "@lana-commerce/core/request";
import { sleep } from "@lana-commerce/core/sleep";

import { T } from "./file.tlocale";

const CHUNK_SIZE = 256 * 1024;

async function readBlobToArrayBuffer(blob: Blob) {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve((reader as any).result);
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

function getFileChunk(file: File, from: number) {
  return readBlobToArrayBuffer(file.slice(from, from + CHUNK_SIZE));
}

function numChunks(file: File) {
  return Math.ceil(file.size / CHUNK_SIZE);
}

export type ResultErrorCode =
  | "create_failure"
  | "upload_failure"
  | "post_failure"
  | "put_failure"
  | "uploaded_failure"
  | "get_status_failure"
  | "is_uploaded_failure";

export type Result = { kind: ResultErrorCode } | { kind: "ok"; data: FileNoImageFallbackFragment };

const errorNiceStringMap = {
  create_failure: T("failed creating a file via API"),
  upload_failure: T("failed initiating file upload via API"),
  post_failure: T("failed initiating resumable storage upload"),
  put_failure: T("failed uploading data"),
  uploaded_failure: T("failed confirming uploaded status"),
  get_status_failure: T("failed polling file status"),
  is_uploaded_failure: T("file not uploaded"),
};

export function resultErrorCodeToString(e: ResultErrorCode) {
  return errorNiceStringMap[e];
}

export async function uploadFileInChunks(env: Environment, file: File): Promise<Result> {
  const opts = { url: `${env.host}/storefront.json`, authToken: authJWT(globalAuthState.auth) };
  const resp1 = await request(createFile)(
    {
      shopID: env.shopID,
      data: {
        name: file.name,
        content_type: file.type,
        size: `${file.size}`,
      },
    },
    opts
  );
  if (resp1.kind !== "data") {
    console.error(prettyPrintRequestResponseError(resp1));
    return { kind: "create_failure" }; // TODO
  }

  const resp2 = await request(uploadFile)({ shopID: env.shopID, fileID: resp1.data[0].id }, opts);
  if (resp2.kind !== "data") {
    console.error(prettyPrintRequestResponseError(resp2));
    return { kind: "upload_failure" }; // TODO
  }

  const resp3 = await fetch(resp2.data.url, {
    method: "POST",
    headers: {
      "Content-Type": "application/octet-stream",
      "x-goog-resumable": "start",
    },
  });
  if (resp3.status !== 201) {
    return { kind: "post_failure" }; // TODO
  }
  const location = resp3.headers.get("location") || "";
  const n = numChunks(file);
  for (let i = 0; i < n; i++) {
    const from = i * CHUNK_SIZE;
    const data = await getFileChunk(file, from);
    const resp4 = await fetch(location, {
      method: "PUT",
      headers: {
        "Content-Type": "application/octet-stream",
        "Content-Range": `bytes ${from}-${from + data.byteLength - 1}/${file.size}`,
      },
      body: data,
    });
    if ([200, 201, 308].indexOf(resp4.status) === -1) {
      return { kind: "put_failure" }; // TODO
    }
  }

  const resp5 = await request(fileUploaded)({ shopID: env.shopID, fileID: resp1.data[0].id }, opts);
  if (resp5.kind !== "data") {
    console.error(prettyPrintRequestResponseError(resp5));
    return { kind: "uploaded_failure" }; // TODO
  }

  const fileID = resp5.data.id;
  while (true) {
    await sleep(750);
    const resp = await request(getFileStatus)({ shopID: env.shopID, fileID }, opts);
    if (resp.kind !== "data") {
      return { kind: "get_status_failure" }; // TODO
    }
    if (resp.data.processed && resp.data.result_file) {
      const f = resp.data.result_file;
      if (!f.is_uploaded) {
        return { kind: "is_uploaded_failure" };
      } else {
        return { kind: "ok", data: f };
      }
    }
  }
}
