import React from "react";
import find from "lodash/find";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import {
  ArrayWrapper,
  BooleanValueWrapper,
  HiddenWrapper,
  NumberValueWrapper,
  ObjectWrapperType,
  StringValueWrapper,
} from "@trunkery/internal/lib/formaline";
import { Rating } from "components/Rating";
import { observer } from "mobx-react";

import { T } from "./ProductRatingWidget.tlocale";

function classifyRating(v: number) {
  if (v === 9) {
    return T("Great");
  } else if (v >= 7) {
    return T("Good");
  } else if (v >= 5) {
    return T("Average");
  } else if (v >= 3) {
    return T("Fair");
  } else {
    return T("Poor");
  }
}

interface ProductRatingWidgetProps {
  scores: ArrayWrapper<
    ObjectWrapperType<{
      review_dimension_id: StringValueWrapper;
      score: NumberValueWrapper;
      name: HiddenWrapper<StringValueWrapper>;
      overall: HiddenWrapper<BooleanValueWrapper>;
    }>
  >;
}

@observer
export class ProductRatingWidget extends React.Component<ProductRatingWidgetProps> {
  renderSingle() {
    const { scores } = this.props;
    return <Rating {...scores.value[0].score.raw} />;
  }

  render() {
    const { scores } = this.props;
    if (scores.value.length === 1) return this.renderSingle();
    const average = scores.value.length > 0 ? sumBy(scores.value, (s) => s.score.value) / scores.value.length : 0;
    const overall = find(scores.value, (s) => s.overall.value.value);
    return (
      <>
        <div style={{ marginBottom: 10 }}>
          <div className="product-rating-average">
            <div className="product-rating-average__stars">
              {overall ? <Rating {...overall.score.raw} /> : <Rating value={average} big allowHalf />}
            </div>
            <div className="product-rating-average__label">
              {overall ? classifyRating(overall.score.value) : classifyRating(average)}
            </div>
          </div>
        </div>
        <div className="product-rating">
          {map(scores.value, (s, idx) =>
            s.overall.value.value ? null : (
              <div key={idx} className="product-rating__line">
                <div className="product-rating__line-label">{s.name.value.value}</div>
                <div className="product-rating__line-stars">
                  <Rating {...s.score.raw} />
                </div>
              </div>
            )
          )}
        </div>
      </>
    );
  }
}
