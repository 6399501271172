import React from "react";
import chunk from "lodash/chunk";
import classnames from "classnames";
import findIndex from "lodash/findIndex";
import map from "lodash/map";
import { ActionLock } from "@lana-commerce/core/actionLock";
import { Environment } from "@trunkery/internal/lib/environment";
import { EventData, Swipeable } from "react-swipeable";
import { Portal } from "@lana-commerce/core/react/components/Portal";
import { RootCloseWrapper } from "@lana-commerce/core/react/components/RootCloseWrapper";
import { action, observable } from "mobx";
import { convertImageURL } from "@lana-commerce/core/convertImageURL";
import { observer } from "mobx-react";
import { sleep } from "@lana-commerce/core/sleep";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";

interface ImageLike {
  id: string;
  public_url: string;
  image_width: number;
  image_height: number;
}

interface LazyImageProps {
  img: ImageLike;
  loaded: boolean;
}

// Custom LazyImage component, it's a bit simpler, shows 128x128 thumbnail until you're ready to replace it with
// real image, we use it to prevent excessive large image loading.
const LazyImage = (props: LazyImageProps) => {
  const { loaded, img } = props;
  const env = useEnvironment();
  const showOriginal = loaded; // || this.loaded;
  if (showOriginal || (img.image_width <= 128 && img.image_height <= 128)) {
    return <img width={img.image_width} height={img.image_height} src={img.public_url} />;
  }

  const scale = Math.max(img.image_width, img.image_height) / 128;
  const w = Math.round(img.image_width / scale);
  const h = Math.round(img.image_height / scale);
  return (
    <img
      width={img.image_width}
      height={img.image_height}
      src={convertImageURL(env.cdn, img.public_url, { size: `${w}x${h}` })}
    />
  );
};

export interface LoadMoreParams {
  totalCount: number;
  perPage: number;
  loadMore: (page: number) => Promise<ImageLike[]>;
}

export class ImageViewDialogState {
  @observable isVisible = false;
  @observable pos = 0;
  @observable.ref images: ImageLike[] = [];
  @observable.ref pageLoaded = observable.map<number, boolean>();
  @observable.ref loadMore?: LoadMoreParams = undefined;

  alock = new ActionLock();

  appendImages = this.alock.proxy(async (images: ImageLike[]) => {
    for (const c of chunk(images, 5)) {
      this.images = [...this.images, ...c];
      await sleep(10);
    }
  });

  @action show(img: ImageLike, images: ImageLike[], loadMore?: LoadMoreParams) {
    this.isVisible = true;
    this.pos = 0;
    if (images.length > 5) {
      this.images = [];
      this.appendImages(images);
    } else {
      this.images = images;
    }
    this.pageLoaded = observable.map<number, boolean>();
    this.loadMore = loadMore;
    const idx = findIndex(images, (v) => v.id === img.id);
    if (idx !== -1) {
      this.pos = idx;
    }
  }
}

interface ImageViewDialogProps {
  state: ImageViewDialogState;
  env: Environment;
}

@observer
export class ImageViewDialog extends React.Component<ImageViewDialogProps> {
  handleCloseClick = () => {
    const { state } = this.props;
    state.isVisible = false;
  };

  renderImages() {
    const { state } = this.props;
    return map(state.images, (img, idx) => {
      const style = {
        transform: `translateX(${idx * 100}%)`,
      };
      const dist = Math.abs(idx - state.pos);
      return (
        <div className="image-modal__image" key={img.id} style={style}>
          <LazyImage img={img} loaded={dist <= 1} />
        </div>
      );
    });
  }

  handleNextClick = () => {
    const { state } = this.props;
    if (state.pos < state.images.length - 1) this.moveTo(state.pos + 1);
  };

  handlePrevClick = () => {
    const { state } = this.props;
    if (state.pos > 0) this.moveTo(state.pos - 1);
  };

  moveTo(idx: number) {
    const { state } = this.props;
    state.pos = idx;

    const lm = state.loadMore;
    if (lm) {
      // page loading logic here
      const page = Math.floor(idx / lm.perPage);
      if (!state.pageLoaded.get(page + 1)) {
        state.pageLoaded.set(page + 1, true);
        lm.loadMore(page + 1).then(state.appendImages);
      }
    }
  }

  handleSwiped = (e: EventData) => {
    switch (e.dir) {
      case "Right":
        this.handlePrevClick();
        break;
      case "Left":
        this.handleNextClick();
        break;
    }
  };

  render() {
    const { state, env } = this.props;
    if (!state.isVisible) return null;
    const beginning = -105 / 2;
    const offset = beginning - state.pos * 105;
    const anchorStyle = {
      transform: `translateX(${-state.pos * 100}%)`,
    };
    return (
      <Portal>
        <div className="image-modal-wrapper">
          <RootCloseWrapper onRootClose={this.handleCloseClick}>
            <div className="image-modal">
              <Swipeable onSwiped={this.handleSwiped} className="image-modal__image-view">
                <div className="image-modal__image-view-anchor" style={anchorStyle}>
                  {this.renderImages()}
                </div>
                <div className="image-modal__close" onClick={this.handleCloseClick}>
                  &times;
                </div>
                <div className="image-modal__next" onClick={this.handleNextClick}>
                  &rsaquo;
                </div>
                <div className="image-modal__prev" onClick={this.handlePrevClick}>
                  &lsaquo;
                </div>
              </Swipeable>
              <div className="image-modal__controls">
                <div className="image-modal__thumbnails-anchor">
                  <div className="image-modal__thumbnails" style={{ transform: `translateX(${offset.toFixed(1)}px)` }}>
                    {map(state.images, (img, idx) => (
                      <div
                        key={img.id}
                        className={classnames("image-modal__thumbnail", {
                          "image-modal__thumbnail--active": idx === state.pos,
                        })}
                        onClick={() => {
                          this.moveTo(idx);
                        }}
                      >
                        <img src={convertImageURL(env.cdn, img.public_url, { crop: "center", size: "97x72" })} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </RootCloseWrapper>
        </div>
      </Portal>
    );
  }
}
