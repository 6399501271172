import React from "react";
import { ProductQuestionAnswerFragment } from "@trunkery/internal/lib/vature-gen/types";
import { QuestionAnswerForm } from "components/QuestionAnswerForm";
import { QuestionsModel } from "utils/questionsModel";

interface EditOrCreateAnswerProps {
  answer?: ProductQuestionAnswerFragment;
  model: QuestionsModel;
  setCollapsibleElement: (e: any) => void;
}

export class EditOrCreateAnswer extends React.Component<EditOrCreateAnswerProps> {
  render() {
    const { answer, model, setCollapsibleElement } = this.props;
    return (
      <QuestionAnswerForm
        setCollapsibleElement={setCollapsibleElement}
        onCancel={model.onCancelAnswer}
        editing={!!answer}
        pending={model.pending}
        formData={model.questionAnswerFormData}
        initialValue={answer}
      />
    );
  }
}
