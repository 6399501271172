import React from "react";
import { FormGroup } from "components/FormGroup";
import { FormInnerProps, formalizeExternal } from "@trunkery/internal/lib/formaline/react";
import { questionAnswerFormDefinition } from "utils/questionsModel";

import { T } from "./QuestionAnswerForm.tlocale";

interface QuestionAnswerFormProps {
  editing: boolean;
  pending: boolean;
  onCancel: () => void;
  setCollapsibleElement: (e: any) => void;
}

export const QuestionAnswerForm = formalizeExternal<typeof questionAnswerFormDefinition, QuestionAnswerFormProps>(
  class extends React.Component<FormInnerProps<typeof questionAnswerFormDefinition, QuestionAnswerFormProps>> {
    render() {
      const {
        form: { text },
        handleSubmit,
        onCancel,
        pending,
        setCollapsibleElement,
      } = this.props;
      return (
        <div className="answer-form-wrapper" ref={setCollapsibleElement}>
          <div style={{ height: 25 }} />
          <form onSubmit={handleSubmit} className="answer-form">
            <div className="answer-form__fields">
              <FormGroup className="answer-form__field" field={text}>
                <div className="answer-form__label">{T("Answer")}</div>
                <textarea className="review-form-textarea" {...text.text} rows={3} />
              </FormGroup>
              <div className="answer-form__buttons">
                <button
                  className="review-button review-button--reversed answer-form__button"
                  disabled={pending}
                  type="button"
                  onClick={onCancel}
                >
                  {T("Cancel")}
                </button>
                <button className="review-button answer-form__button" disabled={pending || !this.props.form.isValid}>
                  {T("Save")}
                </button>
              </div>
            </div>
          </form>
          <div style={{ height: 15 }} />
        </div>
      );
    }
  }
);
