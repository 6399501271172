import React from "react";
import { DateTime } from "luxon";
import { ProductReviewAnswerFragment } from "@trunkery/internal/lib/vature-gen/types";
import { TextBody } from "components/TextBody";

import { T } from "./ReviewItemAnswer.tlocale";

interface ReviewItemAnswerProps {
  item: ProductReviewAnswerFragment;
}

export class ReviewItemAnswer extends React.Component<ReviewItemAnswerProps> {
  render() {
    const { item } = this.props;
    return (
      <div className="product-question-answer">
        <div className="product-question-answer__header">
          <div className="product-question-answer__title">{T("Response")}</div>
          <div className="product-question-answer__date">
            {DateTime.fromISO(item.created_at).toLocaleString(DateTime.DATE_SHORT)}
          </div>
        </div>
        <TextBody
          text={item.text}
          bodyClass="product-question-answer__body"
          showFullClass="product-question-answer__show-full"
          linkText={T("Show Full Response")}
          animateLink
        />
      </div>
    );
  }
}
