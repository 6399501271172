import compact from "lodash/compact";
import createProductQuestion from "gql/operations/CreateProductQuestionMutation";
import createQuestionAnswer from "gql/operations/CreateQuestionAnswerMutation";
import findIndex from "lodash/findIndex";
import flagQuestion from "gql/operations/FlagQuestionMutation";
import flagQuestionAnswer from "gql/operations/FlagQuestionAnswerMutation";
import getQuestion from "gql/operations/GetQuestionQuery";
import modifyProductQuestion from "gql/operations/ModifyProductQuestionMutation";
import searchQuestions from "gql/operations/SearchQuestionsQuery";
import voteQuestion from "gql/operations/VoteQuestionMutation";
import voteQuestionAnswer from "gql/operations/VoteQuestionAnswerMutation";
import { ActionLock } from "@lana-commerce/core/actionLock";
import { Auth, authJWT } from "@trunkery/internal/lib/auth";
import { CreateProductQuestionMutation, ModifyProductQuestionMutation } from "gql/types";
import { Environment } from "@trunkery/internal/lib/environment";
import { FObject, FString, FormData, valid } from "@trunkery/internal/lib/formaline";
import { PAGE_SIZE } from "utils/pageSize";
import { ProductQuestionFragment } from "@trunkery/internal/lib/vature-gen/types";
import { RequestResponse, prettyPrintRequestResponseError, request } from "@lana-commerce/core/request";
import { SortingMode, sortingModeToVarsForQuestions } from "utils/soringMode";
import { action, computed, observable, runInAction } from "mobx";
import { authCallWrapper } from "utils/authCallWrapper";
import { globalAuthState } from "utils/globalAuthState";
import { globalSnackbarState } from "utils/globalSnackbarState";
import { pender } from "@lana-commerce/core/pender";

import { T } from "./questionsModel.tlocale";

export const questionFormDefinition = FObject({
  text: FString(valid.nonEmpty, valid.maxLengthLongText),
});

export const questionAnswerFormDefinition = FObject({
  text: FString(valid.nonEmpty, valid.maxLengthLongText),
});

interface QuestionsModelArgs {
  env: Environment;
  productID: string;
  ownQuestions?: ProductQuestionFragment[];
  questions: ProductQuestionFragment[];
  questionsTotal: number;
  customerID: string;
  loaded: boolean;
}

interface QuestionsModelLoadedArgs {
  ownQuestions: ProductQuestionFragment[];
  questions: ProductQuestionFragment[];
  questionsTotal: number;
  customerID: string;
}

interface PageData {
  items: ProductQuestionFragment[];
  count: number | undefined;
}

function insertPageData<T>(n: number, currentData: T[], newData: T[]) {
  let data = [...currentData];
  data = data.slice(0, n * PAGE_SIZE);
  data.push(...newData);
  return data;
}

// zero-based, e.g. 0 is first page
async function loadNthPage(
  n: number,
  env: Environment,
  productID: string,
  customerID: string,
  sortingMode: SortingMode
): Promise<PageData | undefined> {
  if (customerID !== "") {
    const resp = await request(searchQuestions)(
      {
        shopID: env.shopID,
        productID,
        notCustomerID: customerID,
        offset: n * PAGE_SIZE,
        limit: PAGE_SIZE,
        ...sortingModeToVarsForQuestions(sortingMode),
      },
      { url: `${env.host}/storefront.json`, authToken: globalAuthState.jwt() }
    );
    if (resp.kind === "data") {
      return {
        ...resp.data,
        items: compact(resp.data.items),
      };
    }
  } else {
    const resp = await fetch(`/product-question-data/${productID}/${sortingMode}/${n + 1}.json`);
    if (resp.status === 200) {
      return {
        items: await resp.json(),
        count: undefined,
      };
    }
  }
}

export class QuestionsModel {
  private productID: string;
  private env: Environment;
  alock = new ActionLock(); // had to make it public for authCallWrapper
  //========================================================================================================
  // STATE
  //========================================================================================================

  // global pending state
  @observable pending = false;

  // question answer form state
  questionAnswerFormData: FormData<typeof questionAnswerFormDefinition>;
  @observable questionAnswerFormVisibleOnID: string | undefined;

  // question form state
  questionFormData: FormData<typeof questionFormDefinition>;
  @observable questionFormVisible = false;
  @observable questionFormSubmitted = false;
  @observable questionFormError = false;
  @observable questionFormEditingQuestion: ProductQuestionFragment | undefined;

  @observable.ref ownQuestions: ProductQuestionFragment[] = [];
  @observable.ref questions: ProductQuestionFragment[];
  @observable questionsTotal: number;
  @observable sortMode: SortingMode = "highest_rating";

  @observable customerID: string; // when logged in, this is our customer id

  //========================================================================================================

  @computed get canLoadMore() {
    return this.questions.length !== this.questionsTotal;
  }

  //========================================================================================================
  // ACTIONS
  //========================================================================================================

  onLoaded = action((args: QuestionsModelLoadedArgs) => {
    this.ownQuestions = args.ownQuestions;
    this.questions = args.questions;
    this.questionsTotal = args.questionsTotal;
    this.customerID = args.customerID;
    this.alock.locked = false;
  });

  onShowAnswerForID = action((id: string) => {
    this.questionAnswerFormVisibleOnID = id;
  });

  onCancelAnswer = action(() => {
    this.questionAnswerFormVisibleOnID = undefined;
  });

  onAskQuestionClick = action(() => {
    this.questionFormVisible = true;
    this.questionFormSubmitted = false;
    this.questionFormError = false;
    this.questionFormEditingQuestion = undefined;
  });

  onCancelQuestionClick = action(() => {
    this.questionFormVisible = false;
  });

  onChangeSortMode = this.alock.proxy(async (mode: SortingMode) => {
    this.sortMode = mode;
    loadNthPage(0, this.env, this.productID, this.customerID, mode).then((resp) => {
      if (resp) {
        runInAction(() => {
          this.questions = resp.items;
          if (resp.count) this.questionsTotal = resp.count;
          console.log(`got ${this.questions.length} questions now, total: ${this.questionsTotal}`);
        });
      }
    });
  });

  onLoadMore = this.alock.proxy(async () => {
    const nextPage = Math.floor(this.questions.length / PAGE_SIZE);
    loadNthPage(nextPage, this.env, this.productID, this.customerID, this.sortMode).then((resp) => {
      if (resp) {
        runInAction(() => {
          this.questions = insertPageData(nextPage, this.questions, resp.items);
          if (resp.count) this.questionsTotal = resp.count;
          console.log(`got ${this.questions.length} questions now, total: ${this.questionsTotal}`);
        });
      }
    });
  });

  @action replaceQuestion(q: ProductQuestionFragment) {
    const idx = findIndex(this.questions, (oq) => oq.id === q.id);
    if (idx !== -1) {
      const newQuestions = [...this.questions];
      newQuestions[idx] = q;
      this.questions = newQuestions;
    } else {
      const idx = findIndex(this.ownQuestions, (oq) => oq.id === q.id);
      if (idx !== -1) {
        const newQuestions = [...this.ownQuestions];
        newQuestions[idx] = q;
        this.ownQuestions = newQuestions;
      } else {
        const newQuestions = [...this.ownQuestions];
        newQuestions.splice(0, 0, q);
        this.ownQuestions = newQuestions;
      }
    }
  }

  private async fetchAndReplaceQuestion(questionID: string) {
    const resp = await request(getQuestion)(
      { shopID: this.env.shopID, questionID },
      { url: `${this.env.host}/storefront.json`, authToken: globalAuthState.jwt() }
    );
    if (resp.kind === "data") {
      this.replaceQuestion(resp.data[0]);
    } else {
      console.error(prettyPrintRequestResponseError(resp));
    }
  }

  private doVote = async (overrideAuth: Auth | undefined, questionID: string, y: boolean, del: boolean) => {
    const resp = await request(voteQuestion)(
      {
        shopID: this.env.shopID,
        questionID,
        y,
        delete: del,
      },
      {
        url: `${this.env.host}/storefront.json`,
        authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
      }
    );
    if (resp.kind !== "data") {
      console.error(prettyPrintRequestResponseError(resp));
    } else {
      const msg = del ? T("Question vote removed") : y ? T("Question upvoted") : T("Question downvoted");
      globalSnackbarState.showMessage(msg);
      if (overrideAuth) {
        globalAuthState.applyAuth(overrideAuth);
      } else {
        await this.fetchAndReplaceQuestion(questionID);
      }
    }
  };

  private doVoteAnswer = async (
    overrideAuth: Auth | undefined,
    questionID: string,
    questionAnswerID: string,
    y: boolean,
    del: boolean
  ) => {
    const resp = await request(voteQuestionAnswer)(
      {
        shopID: this.env.shopID,
        questionAnswerID,
        y,
        delete: del,
      },
      {
        url: `${this.env.host}/storefront.json`,
        authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
      }
    );
    if (resp.kind !== "data") {
      console.error(prettyPrintRequestResponseError(resp));
    } else {
      const msg = del
        ? T("Question answer vote removed")
        : y
        ? T("Question answer upvoted")
        : T("Question answer downvoted");
      globalSnackbarState.showMessage(msg);
      if (overrideAuth) {
        globalAuthState.applyAuth(overrideAuth);
      } else {
        await this.fetchAndReplaceQuestion(questionID);
      }
    }
  };

  private doFlag = async (overrideAuth: Auth | undefined, questionID: string, del: boolean) => {
    const resp = await request(flagQuestion)(
      {
        shopID: this.env.shopID,
        questionID,
        delete: del,
      },
      {
        url: `${this.env.host}/storefront.json`,
        authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
      }
    );
    if (resp.kind !== "data") {
      console.error(prettyPrintRequestResponseError(resp));
    } else {
      const msg = del ? T("Question report canceled") : T("Question reported");
      globalSnackbarState.showMessage(msg);
      if (overrideAuth) {
        globalAuthState.applyAuth(overrideAuth);
      } else {
        await this.fetchAndReplaceQuestion(questionID);
      }
    }
  };

  private doFlagAnswer = async (
    overrideAuth: Auth | undefined,
    questionID: string,
    questionAnswerID: string,
    del: boolean
  ) => {
    const resp = await request(flagQuestionAnswer)(
      {
        shopID: this.env.shopID,
        questionAnswerID,
        delete: del,
      },
      {
        url: `${this.env.host}/storefront.json`,
        authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
      }
    );
    if (resp.kind !== "data") {
      console.error(prettyPrintRequestResponseError(resp));
    } else {
      const msg = del ? T("Question answer report canceled") : T("Question answer reported");
      globalSnackbarState.showMessage(msg);
      if (overrideAuth) {
        globalAuthState.applyAuth(overrideAuth);
      } else {
        await this.fetchAndReplaceQuestion(questionID);
      }
    }
  };

  private doSubmitQuestionAnswer = pender(
    this,
    async (overrideAuth: Auth | undefined, data: typeof questionAnswerFormDefinition.data) => {
      const questionID = this.questionAnswerFormVisibleOnID;
      if (!questionID) return;
      const resp = await request(createQuestionAnswer)(
        {
          shopID: this.env.shopID,
          questionID,
          data,
        },
        {
          url: `${this.env.host}/storefront.json`,
          authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
        }
      );
      if (resp.kind !== "data") {
        console.error(prettyPrintRequestResponseError(resp));
      } else {
        globalSnackbarState.showMessage(T("Question answer submitted"));
        if (overrideAuth) {
          globalAuthState.applyAuth(overrideAuth);
        } else {
          await this.fetchAndReplaceQuestion(questionID);
          this.questionAnswerFormVisibleOnID = undefined;
        }
      }
    }
  );

  private doSubmitQuestion = pender(
    this,
    async (overrideAuth: Auth | undefined, data: typeof questionFormDefinition.data) => {
      const ownQuestion = this.questionFormEditingQuestion;
      let resp: RequestResponse<CreateProductQuestionMutation | ModifyProductQuestionMutation>;
      if (ownQuestion) {
        resp = await request(modifyProductQuestion)(
          {
            shopID: this.env.shopID,
            id: ownQuestion.id,
            data: {
              text: data.text,
            },
          },
          {
            url: `${this.env.host}/storefront.json`,
            authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
          }
        );
      } else {
        resp = await request(createProductQuestion)(
          {
            shopID: this.env.shopID,
            data: {
              product_id: this.productID,
              text: data.text,
            },
          },
          {
            url: `${this.env.host}/storefront.json`,
            authToken: overrideAuth ? authJWT(overrideAuth) : globalAuthState.jwt(),
          }
        );
      }
      if (resp.kind === "data") {
        globalSnackbarState.showMessage(ownQuestion ? T("Question modified") : T("Question submitted"));
        if (overrideAuth) {
          globalAuthState.applyAuth(overrideAuth);
        } else {
          const q = resp.data[0];
          runInAction(() => {
            this.questionFormSubmitted = true;
            this.replaceQuestion(q);
          });
        }
      } else {
        console.error(prettyPrintRequestResponseError(resp));
        this.questionFormError = true;
      }
    }
  );

  onVote = authCallWrapper(this, this.doVote);
  onVoteAnswer = authCallWrapper(this, this.doVoteAnswer);
  onFlag = authCallWrapper(this, this.doFlag);
  onFlagAnswer = authCallWrapper(this, this.doFlagAnswer);
  handleSubmitQuestionAnswer = authCallWrapper(this, this.doSubmitQuestionAnswer);
  handleSubmitQuestion = authCallWrapper(this, this.doSubmitQuestion);

  //========================================================================================================
  //========================================================================================================
  constructor(args: QuestionsModelArgs) {
    this.productID = args.productID;
    this.env = args.env;
    this.questions = args.questions;
    this.questionsTotal = args.questionsTotal;
    this.questionAnswerFormData = new FormData(
      "ProductQuestionAnswer",
      questionAnswerFormDefinition,
      this.handleSubmitQuestionAnswer
    );
    this.questionFormData = new FormData("ProductQuestion", questionFormDefinition, this.handleSubmitQuestion);
    this.customerID = args.customerID;
    this.alock.locked = !args.loaded;
  }
}
