import React from "react";
import filter from "lodash/filter";
import map from "lodash/map";
import maxBy from "lodash/maxBy";
import rangeRight from "lodash/rangeRight";
import some from "lodash/some";
import sumBy from "lodash/sumBy";
import toInteger from "lodash/toInteger";
import { LazyImage } from "components/LazyImage";
import { ProductFragment, ReviewSettingsFragment } from "@trunkery/internal/lib/vature-gen/types";
import { ReadonlyRating } from "components/ReadonlyRating";
import { colorFallback } from "utils/colorFallback";
import { convertImageURL } from "@lana-commerce/core/convertImageURL";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";

import { T } from "./ProductReviewsSummary.tlocale";

interface ReviewDimensionSet {
  overall_review_dimension: {
    id: string;
  } | null;
  review_dimensions: Array<{
    id: string;
  } | null> | null;
}

interface ReviewAverageLike {
  review_dimension: {
    id: string;
  } | null;
}

interface ScoreLike {
  sum: number;
  count: number;
  histogram: {
    key: string;
    value: string;
  }[];
}

interface CombinedHistogram {
  bins: number[];
  total: number;
}

interface ImageLike {
  id: string;
  image_width: number;
  image_height: number;
  image_color: string;
  public_url: string;
}

function calcAverageScore(scores: ScoreLike[] | undefined) {
  const sum = sumBy(scores, (s) => s.sum);
  const count = sumBy(scores, (s) => s.count);
  return count !== 0 ? sum / count : 0;
}

function calcNumberOfReviews(scores: ScoreLike[]) {
  const result = maxBy(scores, (s) => s.count);
  return result ? result.count : 0;
}

function filterAverageScores<T extends ReviewAverageLike>(rds: ReviewDimensionSet | null | undefined, items: T[]): T[] {
  if (rds && rds.overall_review_dimension) {
    const oid = rds.overall_review_dimension.id;
    const result = filter(items, (item) => {
      const id = item.review_dimension ? item.review_dimension.id : "";
      return oid === id;
    });
    if (result.length === 1) return result;
  }
  return filter(items, (item) => {
    if (!rds && !item.review_dimension) {
      return true;
    }
    if (rds) {
      const id = item.review_dimension ? item.review_dimension.id : "";
      if (some(rds.review_dimensions, (rd) => !!rd && rd.id === id)) {
        return true;
      }
    }
    return false;
  });
}

// 0 -> 0       0 -> 0
// 1 -> 0       1 -> 0
// 2 -> 1       2 -> 0
// 3 -> 1       3 -> 1
// 4 -> 2       4 -> 1
// 5 -> 2       5 -> 2
// 6 -> 3       6 -> 2
// 7 -> 3       7 -> 3
// 8 -> 4       8 -> 3
// 9 -> 4       9 -> 4
function combineHistograms(scores: ScoreLike[] | undefined): CombinedHistogram {
  const combined = {
    bins: [0, 0, 0, 0, 0],
    total: 0,
  };
  if (!scores) return combined;
  for (const h of scores) {
    for (const hh of h.histogram) {
      const score = toInteger(hh.key.substr(hh.key.length - 1, 1));
      const num = toInteger(hh.value);
      const bin = Math.max(0, Math.floor((score - 1) / 2));
      combined.bins[bin] += num;
      combined.total += num;
    }
  }
  return combined;
}

const starImages = [
  require("images/stars-1.svg").default,
  require("images/stars-2.svg").default,
  require("images/stars-3.svg").default,
  require("images/stars-4.svg").default,
  require("images/stars-5.svg").default,
];

function binPercentage(num: number, total: number) {
  if (total === 0) return "0%";
  return ((num / total) * 100).toFixed(2) + "%";
}

interface HisogramAndGalleryProps {
  hist: CombinedHistogram;
  reviewImages: ImageLike[];
  onViewAllImages: (img: ImageLike) => void;
}

const HisogramAndGallery = (props: HisogramAndGalleryProps) => {
  const { hist, reviewImages, onViewAllImages } = props;
  const env = useEnvironment();
  return (
    <div className="product-reviews-summary__histogram-and-gallery">
      <div className="product-reviews-summary__histogram">
        {map(rangeRight(5), (i) => (
          <div key={i} className="product-reviews-summary__histogram-line">
            <img src={starImages[i]} />
            <div className="product-reviews-summary__histogram-bar">
              <div
                className="product-reviews-summary__histogram-bar-fill"
                style={{ width: binPercentage(hist.bins[i], hist.total) }}
              />
            </div>
            <div className="product-reviews-summary__histogram-count">({hist.bins[i]})</div>
          </div>
        ))}
      </div>
      {reviewImages.length > 0 ? (
        <div className="product-reviews-summary__gallery">
          {map(reviewImages.slice(0, 5), (img) => (
            <div
              key={img.id}
              className="product-reviews-summary__gallery-image"
              onClick={() => {
                onViewAllImages(img);
              }}
            >
              <LazyImage
                fallbackColor={colorFallback(img)}
                src={convertImageURL(env.cdn, img.public_url, { crop: "center", size: "88x88" })}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

interface ProductReviewsSummaryProps {
  product: ProductFragment;
  reviewSettings: ReviewSettingsFragment;

  reviewImages: ImageLike[]; // we will only use first 5
  onViewAllImages: (img: ImageLike) => void;
}

export const ProductReviewsSummary = (props: ProductReviewsSummaryProps) => {
  const { product, reviewSettings, reviewImages, onViewAllImages } = props;
  const rds = reviewSettings.default_review_dimension_set || product.review_dimension_set;
  const scores = filterAverageScores(rds, product.average_scores);
  const hist = combineHistograms(scores);
  const avg = calcAverageScore(scores);
  const count = product ? calcNumberOfReviews(product.average_scores) : 0;
  const recommendedSum = product ? product.recommended_yes_count + product.recommended_no_count : 0;
  const recommendedPercentage =
    (product && recommendedSum > 0
      ? ((product.recommended_yes_count / recommendedSum) * 100).toFixed(0)
      : (0).toFixed(0)) + "%";

  return (
    <div className="product-reviews-summary">
      <div className="product-reviews-summary__title">{T("Customer Reviews")}</div>
      <div className="product-reviews-summary__status-bar">
        {count > 0 ? (
          <div className="product-reviews-summary__average">
            <div className="product-reviews-summary__score">
              <span>{((avg + 1) / 2).toFixed(1)}</span>
              <ReadonlyRating value={avg} big allowHalf />
            </div>
            <div className="product-reviews-summary__based-on">
              {count === 1 ? T("Based on {count} review", { count }) : T("Based on {count} reviews", { count })}
            </div>
            <div className="product-reviews-summary__based-on">
              {T("Recommended by {percentage} of customers", { percentage: recommendedPercentage })}
            </div>
          </div>
        ) : (
          <div className="product-reviews-summary__average">
            <div className="product-reviews-summary__based-on">{T("Be the first to review this product!")}</div>
          </div>
        )}
      </div>
      {count > 0 ? (
        <HisogramAndGallery reviewImages={reviewImages} onViewAllImages={onViewAllImages} hist={hist} />
      ) : null}
    </div>
  );
};
