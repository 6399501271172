import React from "react";
import { LazyImage } from "components/LazyImage";
import { colorFallback } from "utils/colorFallback";
import { convertImageURL } from "@lana-commerce/core/convertImageURL";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";

interface Imager {
  id: string;
  public_url: string;
  image_width: number;
  image_height: number;
  image_color: string;
}

interface ReviewItemImageProps {
  img: Imager;
  onClick: (img: Imager) => void;
}

export const ReviewItemImage = (props: ReviewItemImageProps) => {
  const { img, onClick } = props;
  const env = useEnvironment();
  return (
    <div
      key={img.id}
      className="product-review__image"
      onClick={() => {
        onClick(img);
      }}
    >
      <LazyImage
        fallbackColor={colorFallback(img)}
        src={convertImageURL(env.cdn, img.public_url, { crop: "center", size: "150x150" })}
        width={150}
        height={150}
      />
    </div>
  );
};
