import loginReq from "gql/operations/LoginMutation";
import React, { useState } from "react";
import { Auth, authFromJWTAndEmail } from "@trunkery/internal/lib/auth";
import { DialogRenderArgs } from "components/Dialog";
import { FormData } from "@trunkery/internal/lib/formaline";
import { SignInDialogForm } from "components/SignInDialogForm";
import { mapFieldErrors } from "utils/mapFieldErrors";
import { prettyPrintRequestResponseError, request } from "@lana-commerce/core/request";
import { signInFormDefinition } from "components/SignInForm";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";

interface SignInDialogProps {
  args: DialogRenderArgs<Auth>;
}

export const SignInDialog = (props: SignInDialogProps) => {
  const env = useEnvironment();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (data: typeof signInFormDefinition.data) => {
    setPending(true);
    const resp = await request(loginReq)({ shopID: env.shopID, data }, { url: `${env.host}/storefront.json` });
    if (resp.kind === "data") {
      props.args.ok(authFromJWTAndEmail(resp.data.token, data.email));
    } else {
      if (!mapFieldErrors(resp, formData.propagateErrors)) {
        setError(true);
        console.error(prettyPrintRequestResponseError(resp));
      }
      setPending(false);
    }
  };
  const [formData] = useState(() => new FormData("SignInDialog", signInFormDefinition, handleSubmit));
  return <SignInDialogForm pending={pending} formData={formData} error={error} />;
};
