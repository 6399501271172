import React from "react";
import map from "lodash/map";
import {
  FileNoFallbackFragment,
  ProductFragment,
  ReviewDimensionFragment,
  ReviewSettingsFragment,
  ShopFragment,
} from "@trunkery/internal/lib/vature-gen/types";
import { ImageViewDialog, ImageViewDialogState, LoadMoreParams } from "components/ImageViewDialog";
import { ProductReviewsSummary } from "components/ProductReviewsSummary";
import { QuestionForm } from "components/QuestionForm";
import { QuestionItem } from "components/QuestionItem";
import { QuestionsModel } from "utils/questionsModel";
import { ReviewForm } from "components/ReviewForm";
import { ReviewItem } from "components/ReviewItem";
import { ReviewsModel } from "utils/reviewsModel";
import { SlideToggleWrapper } from "components/SlideToggleWrapper";
import { observer } from "mobx-react";
import { rdsToInitialScoresValue } from "utils/rdsToInitialScoresValue";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";
import { useSiteData } from "utils/useSiteData";

import { T } from "./ProductReviewsAndQuestions.tlocale";

type SortingMode = "most_recent" | "highest_score" | "lowest_score" | "highest_rating" | "lowest_rating";

const questionSortingOptions: { id: SortingMode; label: string }[] = [
  {
    id: "most_recent",
    label: T("Most Recent"),
  },
  {
    id: "highest_rating",
    label: T("Highest Rating"),
  },
  {
    id: "lowest_rating",
    label: T("Lowest Rating"),
  },
];

const reviewSortingOptions: { id: SortingMode; label: string }[] = [
  ...questionSortingOptions,
  {
    id: "highest_score",
    label: T("Highest Score"),
  },
  {
    id: "lowest_score",
    label: T("Lowest Score"),
  },
];

interface ProductQuestionsProps {
  model: QuestionsModel;
}

const ProductQuestions = observer((props: ProductQuestionsProps) => {
  const { model } = props;
  return (
    <div className="product-questions">
      <div className="product-questions__toolbar">
        <button
          type="button"
          className="review-button product-questions__toolbar-item"
          onClick={model.onAskQuestionClick}
        >
          {T("Ask a Question")}
        </button>
        {model.questions.length > 1 ? (
          <select
            className="product-select product-questions__toolbar-item"
            value={model.sortMode}
            onChange={(e) => {
              model.onChangeSortMode(e.target.value as any);
            }}
          >
            {map(questionSortingOptions, (so) => (
              <option key={so.id} value={so.id}>
                {so.label}
              </option>
            ))}
          </select>
        ) : null}
      </div>
      <SlideToggleWrapper
        expanded={model.questionFormVisible}
        render={({ setCollapsibleElement }: any) => (
          <QuestionForm
            setCollapsibleElement={setCollapsibleElement}
            submitted={model.questionFormSubmitted}
            error={model.questionFormError}
            formData={model.questionFormData}
            pending={model.pending}
            initialValue={model.questionFormEditingQuestion}
            editing={!!model.questionFormEditingQuestion}
            onCancel={model.onCancelQuestionClick}
          />
        )}
      />
      <div className="product-questions__questions">
        {model.ownQuestions.length > 0
          ? map(model.ownQuestions, (q) => <QuestionItem item={q} model={model} key={q.id} />)
          : null}
        {map(model.questions, (q) => (
          <QuestionItem item={q} model={model} key={q.id} />
        ))}
      </div>
      {model.canLoadMore ? (
        <div className="product-questions__load-more">
          <button type="button" className="product-button" onClick={model.onLoadMore}>
            {T("Load More")}
          </button>
        </div>
      ) : null}
    </div>
  );
});

interface ProductReviewsProps {
  product: ProductFragment;
  model: ReviewsModel;
  imageViewDialogState: ImageViewDialogState;
  reviewDimensions: ReviewDimensionFragment[];
}

const ProductReviews = observer((props: ProductReviewsProps) => {
  const { product, model, imageViewDialogState } = props;
  const { reviewSettings, reviewDimensions } = useSiteData();
  const env = useEnvironment();
  const rds = reviewSettings.default_review_dimension_set || product.review_dimension_set;
  const ownReview = model.ownReviews.length > 0 ? model.ownReviews[0] : undefined;
  const formProps = {
    env: env,
    submitted: model.reviewFormSubmitted,
    error: model.reviewFormError,
    formData: model.reviewFormData,
    pending: model.pending,
    editing: !!ownReview,
    onCancel: model.onCancelReviewClick,
    loggedIn: !!model.customerID,
    initialValue: ownReview
      ? {
          ...ownReview,
          scores: rdsToInitialScoresValue(rds, reviewDimensions, ownReview.scores),
        }
      : { recommended: "not_voted", scores: rdsToInitialScoresValue(rds, reviewDimensions) },
  }; // should be here, because SlideToggleWrapper is not an observer
  const reviewItemProps = {
    rds,
    reviewDimensions,
    model,
    imageViewDialogState,
  };
  return (
    <>
      <div className="separator" />
      <div className="product-reviews">
        <div className="product-reviews__toolbar">
          <button
            type="button"
            className="review-button product-reviews__toolbar-item"
            onClick={model.onWriteReviewClick}
          >
            {T("Write Review")}
          </button>
          {model.reviews.length > 1 ? (
            <select
              className="product-select product-reviews__toolbar-item"
              value={model.sortMode}
              onChange={(e) => {
                model.onChangeSortMode(e.target.value as any);
              }}
            >
              {map(reviewSortingOptions, (so) => (
                <option key={so.id} value={so.id}>
                  {so.label}
                </option>
              ))}
            </select>
          ) : null}
        </div>
        <SlideToggleWrapper
          expanded={model.reviewFormVisible}
          render={({ setCollapsibleElement }: any) => (
            <ReviewForm {...formProps} setCollapsibleElement={setCollapsibleElement} />
          )}
        />
        <div className="product-reviews__reviews">
          {ownReview ? <ReviewItem {...reviewItemProps} item={ownReview} key={ownReview.id} /> : null}
          {map(model.reviews, (r) => (
            <ReviewItem {...reviewItemProps} item={r} key={r.id} />
          ))}
        </div>
        {model.canLoadMore ? (
          <div className="product-reviews__load-more">
            <button type="button" className="product-button" onClick={model.onLoadMore}>
              {T("Load More")}
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
});

interface ProductReviewsAndQuestionsProps {
  shop: ShopFragment;
  product: ProductFragment;
  reviewSettings: ReviewSettingsFragment;
  reviewDimensions: ReviewDimensionFragment[];

  reviewImages: FileNoFallbackFragment[];
  reviewImagesLoadMore: LoadMoreParams;

  questionsModel: QuestionsModel;
  reviewsModel: ReviewsModel;
  imageViewDialogState: ImageViewDialogState;
}

export const ProductReviewsAndQuestions = observer((props: ProductReviewsAndQuestionsProps) => {
  const {
    product,
    reviewImages,
    reviewImagesLoadMore,
    reviewSettings,
    reviewDimensions,
    shop,
    questionsModel,
    reviewsModel,
    imageViewDialogState,
  } = props;
  const env = useEnvironment();
  return shop.product_review_enabled ? (
    <>
      <div className="page-with-menu__content">
        <div className="separator"></div>
      </div>
      <div className="page-with-menu__content">
        <a id="questions" style={{ position: "relative", display: "block", top: -105 }}></a>
        <ProductQuestions model={questionsModel} />
      </div>
      <div className="page-with-menu__content">
        <div className="separator"></div>
      </div>
      <div className="page-with-menu__content">
        <a id="reviews" style={{ position: "relative", display: "block", top: -105 }}></a>
        <div>
          <ProductReviewsSummary
            product={product}
            reviewSettings={reviewSettings}
            reviewImages={reviewImages}
            onViewAllImages={(img) => {
              imageViewDialogState.show(img, reviewImages, reviewImagesLoadMore);
            }}
          />
          <ProductReviews
            reviewDimensions={reviewDimensions}
            product={product}
            model={reviewsModel}
            imageViewDialogState={imageViewDialogState}
          />
          <ImageViewDialog env={env} state={imageViewDialogState} />
        </div>
      </div>
    </>
  ) : null;
});
