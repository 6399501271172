import React from "react";
import { FormGroup } from "components/FormGroup";
import { FormInnerProps, formalizeExternal } from "@trunkery/internal/lib/formaline/react";
import { questionFormDefinition } from "utils/questionsModel";

import { T } from "./QuestionForm.tlocale";

interface QuestionFormProps {
  pending: boolean;
  submitted: boolean;
  error: boolean;
  editing: boolean;
  onCancel: () => void;
  setCollapsibleElement: (e: any) => void;
}

export const QuestionForm = formalizeExternal<typeof questionFormDefinition, QuestionFormProps>(
  class extends React.Component<FormInnerProps<typeof questionFormDefinition, QuestionFormProps>> {
    render() {
      const {
        form: { text },
        pending,
        submitted,
        error,
        handleSubmit,
        onCancel,
        setCollapsibleElement,
      } = this.props;
      const showMessage = submitted || error;
      const messageText = submitted ? (
        <div>
          <div>{T("Thank you for writing a product question.")}</div>
          <div>{T("Your question will be published in the next few days.")}</div>
        </div>
      ) : error ? (
        <div>{T("Error submitting question.")}</div>
      ) : null;
      const hiddenStyle = showMessage ? { visibility: "hidden" as "hidden" } : undefined;
      return (
        <div className="product-question-form-wrapper" ref={setCollapsibleElement}>
          <div className="product-question-form">
            <div className="product-question-form__title" style={hiddenStyle}>
              {T("Ask a Question")}
            </div>
            <form onSubmit={handleSubmit} style={hiddenStyle}>
              <FormGroup className="product-question-form__field" field={text}>
                <div className="product-question-form__label">{T("Question")}</div>
                <textarea className="review-form-textarea" {...text.text} rows={1} style={{ maxWidth: 550 }} />
              </FormGroup>
              <div className="product-question-form__buttons">
                <button
                  className="review-button review-button--reversed product-question-form__button"
                  disabled={pending}
                  type="button"
                  onClick={onCancel}
                >
                  {T("Cancel")}
                </button>
                <button
                  className="review-button product-question-form__button"
                  disabled={pending || !this.props.form.isValid}
                >
                  {T("Save")}
                </button>
              </div>
            </form>
            {showMessage ? <div className="product-question-form__message">{messageText}</div> : null}
          </div>
          <div style={{ height: 25 }} />
        </div>
      );
    }
  }
);
