import React from "react";
import { StringValueWrapper } from "@trunkery/internal/lib/formaline";
import { observer } from "mobx-react";

interface RecommendedWidgetProps {
  recommended: StringValueWrapper;
}

@observer
export class RecommendedWidget extends React.Component<RecommendedWidgetProps> {
  handleYesClicked = () => {
    const { recommended } = this.props;
    if (recommended.value === "voted_yes") {
      recommended.set("not_voted");
    } else {
      recommended.set("voted_yes");
    }
  };

  handleNoClicked = () => {
    const { recommended } = this.props;
    if (recommended.value === "voted_no") {
      recommended.set("not_voted");
    } else {
      recommended.set("voted_no");
    }
  };

  render() {
    const { recommended } = this.props;
    return (
      <>
        Will you recommend this product to others?
        <div className="vote vote--minimal">
          <div className="vote__thumb-up" onClick={this.handleYesClicked}>
            {recommended.value === "voted_yes" ? (
              <img width={20} height={17} src={require("images/thumb-up-active.svg").default} />
            ) : (
              <img width={20} height={17} src={require("images/thumb-up.svg").default} />
            )}
          </div>
          <div className="vote__thumb-down" onClick={this.handleNoClicked}>
            {recommended.value === "voted_no" ? (
              <img width={20} height={17} src={require("images/thumb-down-active.svg").default} />
            ) : (
              <img width={20} height={17} src={require("images/thumb-down.svg").default} />
            )}
          </div>
        </div>
      </>
    );
  }
}
