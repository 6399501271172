import React from "react";
import { ModelViewerProps } from "components/ModelViewer";
import { modelViewerModule } from "modules/modelViewerModule";
import { useAsyncCachedValue } from "utils/useAsyncCachedValue";

export const ModelViewerAsync = (props: ModelViewerProps) => {
  const m = useAsyncCachedValue(modelViewerModule);
  const ModelViewer = m?.ModelViewer;
  return ModelViewer ? <ModelViewer {...props} /> : <div />;
};
