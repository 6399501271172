import React from "react";
import compact from "lodash/compact";
import find from "lodash/find";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import { DateTime } from "luxon";
import { ImageViewDialogState } from "components/ImageViewDialog";
import { ProductReviewFragment, ReviewDimensionFragment } from "@trunkery/internal/lib/vature-gen/types";
import { ReadonlyRating } from "components/ReadonlyRating";
import { ReportWidget } from "components/ReportWidget";
import { ReviewItemAnswer } from "components/ReviewItemAnswer";
import { ReviewItemImage } from "components/ReviewItemImage";
import { ReviewsModel } from "utils/reviewsModel";
import { TextBody } from "components/TextBody";
import { VoteWidget } from "components/VoteWidget";

import { T } from "./ReviewItem.tlocale";

interface Imager {
  id: string;
  public_url: string;
  image_width: number;
  image_height: number;
}

interface ReviewDimensionSet {
  overall_review_dimension: {
    id: string;
  } | null;
  review_dimensions: Array<{
    id: string;
  } | null> | null;
}

interface ReviewItemProps {
  item: ProductReviewFragment;
  reviewDimensions: ReviewDimensionFragment[];
  rds: ReviewDimensionSet | null | undefined;
  model: ReviewsModel;
  imageViewDialogState: ImageViewDialogState;
}

export class ReviewItem extends React.Component<ReviewItemProps> {
  reviewDimensionName(id: string) {
    const { reviewDimensions } = this.props;
    const rd = find(reviewDimensions, (rd) => rd.id === id);
    return rd ? rd.name : "";
  }

  handleYesVote = () => {
    const { model, item } = this.props;
    model.onVote(item.id, true, item.voted === "voted_yes");
  };

  handleNoVote = () => {
    const { model, item } = this.props;
    model.onVote(item.id, false, item.voted === "voted_no");
  };

  handleImageClick = (img: Imager) => {
    const { item, imageViewDialogState } = this.props;
    imageViewDialogState.show(img, compact(item.image_files));
  };

  handleDeleteClick = () => {
    const { item, model } = this.props;
    model.onDelete(item.id);
  };

  render() {
    const { item, rds, model } = this.props;
    const anon = T("Vature Customer");
    const average = item.scores.length > 0 ? sumBy(item.scores, (s) => s.score) / item.scores.length : 0;
    const overallID = rds && rds.overall_review_dimension ? rds.overall_review_dimension.id : "";
    const overall = find(item.scores, (s) => !!s.review_dimension && s.review_dimension.id === overallID);
    return (
      <div className="product-review">
        <div className="product-review__info">
          <div className="product-review__name">{item.customer ? item.customer.display_name || anon : anon}</div>
          <div className="product-review__date">
            {DateTime.fromISO(item.created_at).toLocaleString(DateTime.DATE_SHORT)}
          </div>
          {item.verified_purchase ? (
            <div className="product-review__verified">
              <img src={require("images/badge-tick-1.svg").default} />
              <span>{T("Verified Review")}</span>
            </div>
          ) : null}
          <div className="product-review__was-helpful">{T("Was this review helpful?")}</div>
          <div className="product-review__vote">
            <VoteWidget
              yes_count={item.yes_count}
              no_count={item.no_count}
              voted={item.voted}
              onVoteYes={() => {
                model.onVote(item.id, true, false);
              }}
              onVoteNo={() => {
                model.onVote(item.id, false, false);
              }}
              onVoteDelete={() => {
                model.onVote(item.id, false, true);
              }}
            />
          </div>
          {item.customer && item.customer.id === model.customerID ? (
            <div className="product-review__delete">
              <span onClick={this.handleDeleteClick}>{T("Delete")}</span>
            </div>
          ) : null}
        </div>
        <div className="product-review__content">
          {item.customer && item.customer.id === model.customerID ? (
            <img className="product-review__my" src={require("images/my.svg").default} />
          ) : null}
          <div className="product-review__title">{item.title}</div>
          <div className="product-review__rating-average">
            <ReadonlyRating value={overall ? overall.score : average} big allowHalf />
          </div>
          {item.scores.length > 1 ? (
            <div className="product-review__ratings">
              <div className="product-review__rating">
                {map(item.scores, (s, idx) =>
                  s.review_dimension && s.review_dimension.id === overallID ? null : (
                    <div key={idx} className="product-review__rating-line">
                      <div className="product-review__rating-line-label">
                        {s.review_dimension ? this.reviewDimensionName(s.review_dimension.id) : T("General")}
                      </div>
                      <div className="product-review__rating-line-stars">
                        <ReadonlyRating value={s.score} />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null}
          <TextBody
            text={item.text}
            bodyClass="product-review__body"
            showFullClass="product-review__show-full"
            linkText={T("Show Full Review")}
            animateLink
          />
          {item.image_files && item.image_files.length > 0 ? (
            <div className="product-review__images">
              {map(item.image_files, (img) =>
                img ? <ReviewItemImage key={img.id} img={img} onClick={this.handleImageClick} /> : null
              )}
            </div>
          ) : null}
          <div className="product-review__report">
            <ReportWidget
              flagged={item.flagged}
              onFlag={() => {
                model.onFlag(item.id, false);
              }}
              onUnflag={() => {
                model.onFlag(item.id, true);
              }}
            />
          </div>
          {item.answers && item.answers.length > 0 ? (
            <div className="product-review__response">
              {map(item.answers, (a) => (a ? <ReviewItemAnswer key={a.id} item={a} /> : null))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
