import React from "react";
import { ActionLock } from "@lana-commerce/core/actionLock";
import { SignInDialog } from "components/SignInDialog";
import { globalDialogState } from "utils/globalDialogState";

import { T } from "./authCallWrapper.tlocale";

type OmitFirstParameter<F> = F extends (a: any, ...args: infer P) => infer R ? (...args: P) => R : never;

interface Model {
  alock: ActionLock;
  customerID: string;
}

export function authCallWrapper<T extends (...args: any[]) => Promise<void>>(
  model: Model,
  fn: T
): OmitFirstParameter<T> {
  return model.alock.proxy(async (...args: any[]) => {
    if (!model.customerID) {
      const result = await globalDialogState.showDialog(T("Sign In"), (args) => <SignInDialog args={args} />);
      if (result.kind === "cancel") return;
      return fn(result.data, ...args);
    } else {
      return fn(undefined, ...args);
    }
  }) as OmitFirstParameter<T>;
}
