import FileNoFallback from "@trunkery/internal/lib/vature-gen/fragments/FileNoFallback";
import ProductReview from "@trunkery/internal/lib/vature-gen/fragments/ProductReview";
import ProductReviewAnswer from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewAnswer";
import ProductReviewScore from "@trunkery/internal/lib/vature-gen/fragments/ProductReviewScore";
import ReviewDimension from "@trunkery/internal/lib/vature-gen/fragments/ReviewDimension";

import type { GetReviewQueryMeta } from "../types";

export default (FileNoFallback +
  ProductReview +
  ProductReviewAnswer +
  ProductReviewScore +
  ReviewDimension +
  "query GetReview($shopID:String!$reviewID:String!){storefrontProductReviews(shop_id:$shopID ids:[$reviewID]){...ProductReview}}") as unknown as GetReviewQueryMeta;
